/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { CreateMassReportPayload, Evaluation, EvaluationStatus as Status } from "src/app/types/api/evaluation.types";
import { Button, Modal } from "flowbite-react";
import Table from "src/app/components/Utils/Table.component";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { Nullable, SortCompare } from "src/app/types/util.types";
import { evaluationStatusDictionary } from "src/app/utils/constants/dictionaries";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import EvaluationStatus from "src/app/components/Evaluation/EvaluationStatus.component";
import Avatar from "src/app/components/Utils/Avatar.component";
import { DateTime } from "luxon";
import { MODAL_TABLE_FIXED_ROWS_PER_PAGE } from "src/app/utils/constants/constants";
import { Form } from "src/app/types/ui/form.types";
import React from "react";
import EvaluationButton from "src/app/components/Utils/EvaluationButton";
import { Close } from "@material-ui/icons";

type Props = {
	isOpen: boolean
	handleClose: () => void
	handleClearIds: () => void
	form: Form<CreateMassReportPayload>
	handleChange: (prop: keyof CreateMassReportPayload, value: any) => void
	handleBlur: (prop: keyof CreateMassReportPayload) => void
	evaluations: Evaluation[]
};

const ChooseEvaluationsToTakeAverageModal = (props: Props) => {

	const {
		isOpen,
		handleClose,
		handleClearIds,
		form,
		handleChange,
		handleBlur,
		evaluations,
	} = props;

	const evaluationColumns: MUIDataTableColumn[] = [
		{
			name: "Name",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (evaluation: Evaluation) =>
					<>{ evaluation.name }</>,
				sortCompare: order => (a: SortCompare<Evaluation>, b: SortCompare<Evaluation>) => {
					if (order === "asc") {
						return a.data.name.localeCompare(b.data.name);
					} else {
						return b.data.name.localeCompare(a.data.name);
					}
				},
			},
		}, {
			name: "Klient",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (evaluation: Evaluation) => evaluation.companyName,
				sortCompare: order => (a: SortCompare<Evaluation>, b: SortCompare<Evaluation>) => {
					if (order === "asc") {
						return a.data.companyName.localeCompare(b.data.companyName);
					} else {
						return b.data.companyName.localeCompare(a.data.companyName);
					}
				},
			},
		}, {
			name: "Status",
			options: {
				filter: true,
				filterOptions: {
					names: Object
						.values<Status>(Status)
						.map(status => evaluationStatusDictionary[ status ]),
					logic: (item, filters, row) => {
						if (isNull(row) || isNull(row[ 0 ])) return false;
						const evaluation: Nullable<Evaluation> = row[ 0 ];
						if (isNull(evaluation)) return false;
						const status = evaluation.evaluationStatus;
						return isNotNull(row) && !filters.map(filterElement => filterElement.toLowerCase()).includes(evaluationStatusDictionary[ status ].toLowerCase());
					},
				},
				sort: true,
				customBodyRender: (evaluation: Evaluation) => <EvaluationStatus status={ evaluation.evaluationStatus }/>,
				sortCompare: order => (a: SortCompare<Evaluation>, b: SortCompare<Evaluation>) => {
					if (order === "asc") {
						return evaluationStatusDictionary[ a.data.evaluationStatus ].localeCompare(evaluationStatusDictionary[ b.data.evaluationStatus ]);
					} else {
						return evaluationStatusDictionary[ b.data.evaluationStatus ].localeCompare(evaluationStatusDictionary[ a.data.evaluationStatus ]);
					}
				},
			},
		}, {
			name: "Project Manager",
			options: {
				filter: true,
				filterOptions: {
					names: evaluations
						.map(evaluation => `${ evaluation.coordinatorUser.name } ${ evaluation.coordinatorUser.surname }`)
						.filter((name, index, arr) => arr.indexOf(name) === index),
					logic: (item, filters, row) => {
						if (isNull(row) || isNull(row[ 0 ])) return false;
						const evaluation: Nullable<Evaluation> = row[ 0 ];
						if (isNull(evaluation)) return false;
						const coordinatorName = `${ evaluation.coordinatorUser.name } ${ evaluation.coordinatorUser.surname }`;
						return isNotNull(row) && !filters.map(filterElement => filterElement.toLowerCase()).includes(coordinatorName.toLowerCase());
					},
				},
				sort: true,
				customBodyRender: (evaluation: Evaluation) =>
					<div className="flex items-center gap-2">
						<Avatar
							alt={ `${ evaluation.coordinatorUser.name }-${ evaluation.coordinatorUser.surname }-avatar` }
							img={ evaluation.coordinatorUser.image?.icon }
							placeholderInitials={ `${ evaluation.coordinatorUser.name[ 0 ] }${ evaluation.coordinatorUser.surname[ 0 ] }` }
							size="sm"
						/>
						<span>{ `${ evaluation.coordinatorUser.name } ${ evaluation.coordinatorUser.surname }` }</span>
					</div>,
				sortCompare: order => (a: SortCompare<Evaluation>, b: SortCompare<Evaluation>) => {
					const coordinatorUserA = `${ a.data.coordinatorUser.name } ${ a.data.coordinatorUser.surname }`;
					const coordinatorUserB = `${ b.data.coordinatorUser.name } ${ b.data.coordinatorUser.surname }`;
					if (order === "asc") {
						return coordinatorUserA.localeCompare(coordinatorUserB);
					} else {
						return coordinatorUserB.localeCompare(coordinatorUserA);
					}
				},
			},
		}, {
			name: "Data utworzenia",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (evaluation: Evaluation) => DateTime.fromISO(evaluation.createdAt).toFormat("d MMM yyyy"),
				sortCompare: order => (a: SortCompare<Evaluation>, b: SortCompare<Evaluation>) => {
					if (order === "asc") {
						return DateTime.fromISO(a.data.createdAt).toMillis() - DateTime.fromISO(b.data.createdAt).toMillis();
					} else {
						return DateTime.fromISO(b.data.createdAt).toMillis() - DateTime.fromISO(a.data.createdAt).toMillis();
					}
				},
			},
		},
	];

	const selectedIndexes = form.evaluationIdsToTakeAverage.value.map(formEvaluation => evaluations.findIndex(evaluation => evaluation.id === formEvaluation)).filter(isNotNull);

	const tableOptions: MUIDataTableOptions = {
		selectableRows: "multiple",
		rowsSelected: selectedIndexes,
		rowsPerPage: MODAL_TABLE_FIXED_ROWS_PER_PAGE,
		rowsPerPageOptions: [],
		onRowClick: (_: string[], rowMeta: { dataIndex: number, rowIndex: number }) => {
			const evaluation = evaluations.find((_, i) => i === rowMeta.dataIndex);

			if (isNull(evaluation)) return;

			if (form.evaluationIdsToTakeAverage.value.includes(evaluation.id)) {
				handleChange("evaluationIdsToTakeAverage", form.evaluationIdsToTakeAverage.value.filter(formEvaluation => formEvaluation !== evaluation.id));
			} else {
				handleChange("evaluationIdsToTakeAverage", [ ...form.evaluationIdsToTakeAverage.value, evaluation.id ]);
			}
			handleBlur("evaluationIdsToTakeAverage");
		},
		onRowSelectionChange: (currentRowsSelected: any[], allRowsSelected: any[], rowsSelected?: number[]) => {
			const newEvaluations = evaluations.filter((_, i) => (rowsSelected ?? []).includes(i));
			handleChange("evaluationIdsToTakeAverage", newEvaluations.map(evaluation => evaluation.id));
			handleBlur("evaluationIdsToTakeAverage");
		},
		customSearch: (searchQuery: string, currentRow: Evaluation[]) => {
			searchQuery = searchQuery.toLowerCase();
			const row = currentRow[ 0 ];
			const name = row.name;

			if (name.toLowerCase().includes(searchQuery)) {
				return true;
			}

			return false;
		},
	};

	return (
		<Modal
			show={ isOpen }
			onClose={ () => {
				handleClearIds();
				handleClose();
			} }
			size="4xl"
			root={ document.body }
			key={ (isOpen) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>Wybierz badania</Modal.Header>
			<Modal.Body>
				<div className="flex flex-row flex-wrap gap-2 mt-2 mb-3 items-center">
					{
						form.evaluationIdsToTakeAverage.value.map((evaluation) => {
								return (
									<EvaluationButton
										onClick={ () => {
											handleChange("evaluationIdsToTakeAverage", form.evaluationIdsToTakeAverage.value.filter(filterEvaluation => !(filterEvaluation === evaluation)));
											handleBlur("evaluationIdsToTakeAverage");
										} }
									>
										{ evaluations.find(e => e.id === evaluation)?.name }
										<Close/>
									</EvaluationButton>
								);
							},
						)
					}
					{
						form.evaluationIdsToTakeAverage.value.length !== 0 &&
                        <EvaluationButton
                            onClick={ () => {
								handleChange("evaluationIdsToTakeAverage", []);
								handleBlur("evaluationIdsToTakeAverage");
							} }
                        >
                            Usuń wszystkie
                        </EvaluationButton>
					}
				</div>
				<Table
					title="Badania do średniej"
					columns={ evaluationColumns }
					options={ tableOptions }
					data={ evaluations }
					cellHeight={ 53 }
					isNestedCard
					hideSelectAll
				/>
				{
					form.evaluationIdsToTakeAverage.error &&
                    <span className="text-red-600 text-sm mt-2">{ form.evaluationIdsToTakeAverage.error }</span>
				}
				<div className="w-full flex flex-row-reverse mt-3 gap-3">
					<Button
						type="submit"
						disabled={ !!form.evaluationIdsToTakeAverage.error || form.evaluationIdsToTakeAverage.value.length === 0 }
						onClick={ handleClose }
					>
						<span>Wybierz</span>
					</Button>
					<Button
						color="gray"
						onClick={ () => {
							handleClose();
							handleClearIds();
						} }
					>
						<span>Anuluj</span>
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ChooseEvaluationsToTakeAverageModal;

import { EnumDictionary } from "src/app/types/util.types";
import { ErrorCodeName } from "src/app/types/api/api.types";
import { PermissionGroup, UserPermission, UserRole } from "src/app/types/api/user.types";
import { FlowbiteColors } from "flowbite-react";
import { EvaluationLanguage, EvaluationScheduledStartType, EvaluationStatus, EvaluationType } from "src/app/types/api/evaluation.types";
import { ParticipantSurveyStatus, ParticipantType, SurveyLanguage } from "src/app/types/api/participant.types";

export const errorCodeNameDictionary: EnumDictionary<ErrorCodeName, string> = {
	[ ErrorCodeName.GENERIC ]: "Server error occurred",
	[ ErrorCodeName.GENERIC_NOT_FOUND ]: "Not found",
	[ ErrorCodeName.GENERIC_NOT_SUPPORTED ]: "Not supported",
	[ ErrorCodeName.GENERIC_NOT_IMPLEMENTED ]: "Not implemented",
	[ ErrorCodeName.GENERIC_THROTTLE_TOO_MANY_REQUESTS ]: "Too many requests",
	[ ErrorCodeName.UNAUTHORIZED_SIGNATURE_INVALID ]: "Invalid signature",
	[ ErrorCodeName.GENERIC_METHOD_NOT_ALLOWED ]: "Method not allowed",
	[ ErrorCodeName.GENERIC_HEALTHCHECK_FAILED ]: "Healthcheck failed",
	[ ErrorCodeName.GENERIC_UNAUTHENTICATED ]: "Unauthenticated",
	[ ErrorCodeName.UNAUTHENTICATED_INVALID_CREDENTIALS ]: "Invalid credentials",
	[ ErrorCodeName.UNAUTHENTICATED_MISSING_2FA_OTP ]: "Missing 2FA",
	[ ErrorCodeName.UNAUTHENTICATED_INVALID_2FA_OTP ]: "Invalid 2FA",
	[ ErrorCodeName.UNAUTHENTICATED_RESTRICTED_ACCESS ]: "Restricted access",
	[ ErrorCodeName.UNAUTHENTICATED_USER_EMAIL_NOT_CONFIRMED ]: "User email not confirmed",
	[ ErrorCodeName.GENERIC_VALIDATION_FAIL ]: "Validation fail",
	[ ErrorCodeName.GENERIC_UNAUTHORIZED ]: "Unauthorized",
	[ ErrorCodeName.GENERIC_INVALID_OPERATION ]: "Invalid operation",
};

export const userPermissionsDictionary: EnumDictionary<UserPermission, string> = {
	[ UserPermission.APP_ADMINISTRATION ]: "App administration",
	[ UserPermission.OTHER_MANAGER_PERFORM ]: "Other manager perform",

	[ UserPermission.USER_CREATE ]: "Create user",
	[ UserPermission.USER_VIEW ]: "View user",
	[ UserPermission.USER_LIST ]: "List users",
	[ UserPermission.USER_UPDATE ]: "Update user",
	[ UserPermission.USER_MANAGE ]: "Manage user",
	[ UserPermission.USER_MANAGE_ADMIN ]: "Admin manage user",
	[ UserPermission.USER_DELETE ]: "Delete user",

	[ UserPermission.USER_SCOPE_ALL ]: "Operate on all users",
	[ UserPermission.USER_SCOPE_COMPANY ]: "Operate on all users inside company",
	[ UserPermission.USER_PREVIEW_SCOPE_ALL ]: "View all users",
	[ UserPermission.USER_PREVIEW_SCOPE_COMPANY ]: "View all users from company",

	[ UserPermission.COMPANY_CREATE ]: "Create company",
	[ UserPermission.COMPANY_VIEW ]: "View company",
	[ UserPermission.COMPANY_LIST ]: "List companies",
	[ UserPermission.COMPANY_UPDATE ]: "Update company",
	[ UserPermission.COMPANY_DELETE ]: "Delete company",

	[ UserPermission.COMPANY_SCOPE_ALL ]: "Operate on all companies",
	[ UserPermission.COMPANY_PREVIEW_SCOPE_ALL ]: "View all companies",

	[ UserPermission.MODEL_CREATE ]: "Create model",
	[ UserPermission.MODEL_VIEW ]: "View model",
	[ UserPermission.MODEL_LIST ]: "List models",
	[ UserPermission.MODEL_UPDATE ]: "Update model",
	[ UserPermission.MODEL_DELETE ]: "Delete model",

	[ UserPermission.MODEL_SCOPE_ALL ]: "Operate on all models",
	[ UserPermission.MODEL_SCOPE_COMPANY ]: "Operate on all models inside company",
	[ UserPermission.MODEL_PREVIEW_SCOPE_ALL ]: "View all models",
	[ UserPermission.MODEL_PREVIEW_SCOPE_COMPANY ]: "View all models from company",
};

export const userPermissionGroupDictionary: EnumDictionary<PermissionGroup, string> = {
	[ PermissionGroup.USERS ]: "Users",
	[ PermissionGroup.COMPANIES ]: "Companies",
	[ PermissionGroup.MODELS ]: "Models",
	[ PermissionGroup.OTHERS ]: "Others",
};

export const userPermissionGroupAggregationDictionary: EnumDictionary<PermissionGroup, UserPermission[]> = {
	[ PermissionGroup.USERS ]: [
		UserPermission.USER_CREATE,
		UserPermission.USER_VIEW,
		UserPermission.USER_LIST,
		UserPermission.USER_UPDATE,
		UserPermission.USER_MANAGE,
		UserPermission.USER_MANAGE_ADMIN,
		UserPermission.USER_DELETE,
		UserPermission.USER_SCOPE_ALL,
		UserPermission.USER_SCOPE_COMPANY,
		UserPermission.USER_PREVIEW_SCOPE_ALL,
		UserPermission.USER_PREVIEW_SCOPE_COMPANY,
	],
	[ PermissionGroup.COMPANIES ]: [
		UserPermission.COMPANY_CREATE,
		UserPermission.COMPANY_VIEW,
		UserPermission.COMPANY_LIST,
		UserPermission.COMPANY_UPDATE,
		UserPermission.COMPANY_DELETE,
		UserPermission.COMPANY_SCOPE_ALL,
		UserPermission.COMPANY_PREVIEW_SCOPE_ALL,
	],
	[ PermissionGroup.MODELS ]: [
		UserPermission.MODEL_CREATE,
		UserPermission.MODEL_VIEW,
		UserPermission.MODEL_LIST,
		UserPermission.MODEL_UPDATE,
		UserPermission.MODEL_DELETE,
		UserPermission.MODEL_SCOPE_ALL,
		UserPermission.MODEL_SCOPE_COMPANY,
		UserPermission.MODEL_PREVIEW_SCOPE_ALL,
		UserPermission.MODEL_PREVIEW_SCOPE_COMPANY,
	],
	[ PermissionGroup.OTHERS ]: [
		UserPermission.APP_ADMINISTRATION,
		UserPermission.OTHER_MANAGER_PERFORM,
	],
};

export const userRoleDictionary: EnumDictionary<UserRole, string> = {
	[ UserRole.SUPER_ADMIN ]: "Administrator",
	[ UserRole.TEAM_MEMBER ]: "Członek zespołu",
	[ UserRole.ORGANIZATION_ADMIN ]: "Administrator organizacji",
	[ UserRole.ORGANIZATION_EMPLOYEE ]: "Pracownik organizacji",
};

export const userRoleColorDictionary: EnumDictionary<UserRole, keyof FlowbiteColors> = {
	[ UserRole.SUPER_ADMIN ]: "red",
	[ UserRole.TEAM_MEMBER ]: "green",
	[ UserRole.ORGANIZATION_ADMIN ]: "indigo",
	[ UserRole.ORGANIZATION_EMPLOYEE ]: "gray",
};

// Evaluations
export const evaluationStatusDictionary: EnumDictionary<EvaluationStatus, string> = {
	[ EvaluationStatus.CREATED ]: "Utworzone",
	[ EvaluationStatus.STARTED ]: "Rozpoczęte",
	[ EvaluationStatus.PUBLISHED ]: "Opublikowane",
	[ EvaluationStatus.FINISHED ]: "Zakończone",
};

export const evaluationTypeDictionary: EnumDictionary<EvaluationType, string> = {
	[ EvaluationType.MPI ]: "MPI",
	[ EvaluationType.LPI ]: "LPI",
};

export const evaluationTypeDescriptionDictionary: EnumDictionary<EvaluationType, string> = {
	[ EvaluationType.MPI ]: "Menadżer",
	[ EvaluationType.LPI ]: "Lider",
};

export const evaluationLanguageDictionary: EnumDictionary<EvaluationLanguage, string> = {
	[ EvaluationLanguage.POLISH ]: "Polski",
	[ EvaluationLanguage.ENGLISH ]: "Angielski",
};

export const evaluationScheduledStartTypeDictionary: EnumDictionary<EvaluationScheduledStartType, string> = {
	[ EvaluationScheduledStartType.SCHEDULED_AT ]: "Zaplanowany",
	[ EvaluationScheduledStartType.MANUAL ]: "Manualnie",
};

export const participantSurveyStatusDictionary: EnumDictionary<ParticipantSurveyStatus, string> = {
	[ ParticipantSurveyStatus.NOT_SENT ]: "Nie wysłano",
	[ ParticipantSurveyStatus.NOT_OPENED ]: "Nie otwarto",
	[ ParticipantSurveyStatus.OPENED ]: "Otwarto",
	[ ParticipantSurveyStatus.IN_PROGRESS ]: "W trakcie",
	[ ParticipantSurveyStatus.COMPLETED ]: "Wypełniono",
};

export const participantTypeDictionary: EnumDictionary<ParticipantType, string> = {
	[ ParticipantType.SUBJECT ]: "Osoba badana",
	[ ParticipantType.SUPERVISOR ]: "Przełożony",
	[ ParticipantType.SUBORDINATE ]: "Podwładny",
	[ ParticipantType.COLLEAGUE ]: "Współpracownik",
	[ ParticipantType.ADDITIONAL_ROLE_ONE ]: "Dodatkowa Rola 1",
	[ ParticipantType.ADDITIONAL_ROLE_TWO ]: "Dodatkowa Rola 2",
};

export const surveyLanguageDictionary: EnumDictionary<SurveyLanguage, string> = {
	[ SurveyLanguage.POLISH ]: "Polski",
	[ SurveyLanguage.ENGLISH ]: "Angielski",
};

import { ChangeEvaluationManagerRequestPayload, CreateMassReportPayload, Evaluation, EvaluationStatus as Status } from "src/app/types/api/evaluation.types";
import Table from "src/app/components/Utils/Table.component";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { ModalConfig, Nullable, SortCompare } from "src/app/types/util.types";
import { isEmptyString, isNotNull, isNull } from "src/app/utils/typeguards";
import { canDeleteEvaluation, canLoggedUserCreateEvaluation, canViewEvaluation } from "src/app/utils/abilities";
import { Button, Card, Tooltip } from "flowbite-react";
import { HiCheckCircle, HiOutlineTrash, HiUsers } from "react-icons/hi";
import { useContext, useState } from "react";
import ConfirmModal from "src/app/components/Utils/ConfirmModal.component";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import useUserScopeNavigate from "src/app/utils/hooks/useUserScopeNavigate";
import { LoggedUserContext } from "src/app/hoc/providers/LoggedUser.provider";
import { Link } from "react-router-dom";
import { evaluationStatusDictionary } from "src/app/utils/constants/dictionaries";
import EvaluationStatus from "src/app/components/Evaluation/EvaluationStatus.component";
import { DateTime } from "luxon";
import Avatar from "src/app/components/Utils/Avatar.component";
import { BsFillTicketFill } from "react-icons/bs";
import { GoHourglass } from "react-icons/go";
import EditEvaluationCoordinatorUserModal from "src/app/components/Evaluation/EditEvaluationCoordinatorUserModal.component";
import { FaFlagCheckered } from "react-icons/fa";
import CreateMassReportModal from "src/app/components/Evaluation/CreateMassReportModal.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		evaluations: Evaluation[]
		onChangeEvaluationCoordinatorUser: (payload: ChangeEvaluationManagerRequestPayload) => void
		onArchiveEvaluation: (evaluationId: number) => void
		onDeleteEvaluation: (evaluationId: number) => void
		onCreateMassReport: (payload: CreateMassReportPayload) => void
	};

function EvaluationListContainer(props: Props) {
	const {
		evaluations,
		onChangeEvaluationCoordinatorUser,
		onArchiveEvaluation,
		onDeleteEvaluation,
		isDeleting,
		isArchiving,
		onCreateMassReport,
	} = props;

	const { navigate, getLink } = useUserScopeNavigate();
	const loggedUser = useContext(LoggedUserContext);

	const [ archiveEvaluationModal, setArchiveEvaluationModal ] = useState<ModalConfig<Evaluation>>({
		isOpen: false,
		value: null,
	});
	const [ changeEvaluationCoordinatorModal, setChangeEvaluationCoordinatorModal ] = useState<ModalConfig<Evaluation>>({
		isOpen: false,
		value: null,
	});
	const [ deleteEvaluationModal, setDeleteEvaluationModal ] = useState<ModalConfig<Evaluation>>({
		isOpen: false,
		value: null,
	});
	const [ createMassReportModal, setCreateMassReportModal ] = useState<ModalConfig<Evaluation>>({
		isOpen: false,
		value: null,
	});

	const evaluationColumns: MUIDataTableColumn[] = [
		{
			name: "Nazwa",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (evaluation: Evaluation) => evaluation.name,
				sortCompare: order => (a: SortCompare<Evaluation>, b: SortCompare<Evaluation>) => {
					if (order === "asc") {
						return a.data.name.localeCompare(b.data.name);
					} else {
						return b.data.name.localeCompare(a.data.name);
					}
				},
			},
		}, {
			name: "Klient",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (evaluation: Evaluation) => evaluation.companyName,
				sortCompare: order => (a: SortCompare<Evaluation>, b: SortCompare<Evaluation>) => {
					if (order === "asc") {
						return a.data.companyName.localeCompare(b.data.companyName);
					} else {
						return b.data.companyName.localeCompare(a.data.companyName);
					}
				},
			},
		}, {
			name: "Status",
			options: {
				filter: true,
				filterOptions: {
					names: Object
						.values<Status>(Status)
						.map(status => evaluationStatusDictionary[ status ]),
					logic: (item, filters, row) => {
						if (isNull(row) || isNull(row[ 0 ])) return false;
						const evaluation: Nullable<Evaluation> = row[ 0 ];
						if (isNull(evaluation)) return false;
						const status = evaluation.evaluationStatus;
						return isNotNull(row) && !filters.map(filterElement => filterElement.toLowerCase()).includes(evaluationStatusDictionary[ status ].toLowerCase());
					},
				},
				sort: true,
				customBodyRender: (evaluation: Evaluation) => <EvaluationStatus status={ evaluation.evaluationStatus }/>,
				sortCompare: order => (a: SortCompare<Evaluation>, b: SortCompare<Evaluation>) => {
					if (order === "asc") {
						return evaluationStatusDictionary[ a.data.evaluationStatus ].localeCompare(evaluationStatusDictionary[ b.data.evaluationStatus ]);
					} else {
						return evaluationStatusDictionary[ b.data.evaluationStatus ].localeCompare(evaluationStatusDictionary[ a.data.evaluationStatus ]);
					}
				},
			},
		}, {
			name: "Project Manager",
			options: {
				filter: true,
				filterOptions: {
					names: evaluations
						.map(evaluation => `${ evaluation.coordinatorUser.name } ${ evaluation.coordinatorUser.surname }`)
						.filter((name, index, arr) => arr.indexOf(name) === index),
					logic: (item, filters, row) => {
						if (isNull(row) || isNull(row[ 0 ])) return false;
						const evaluation: Nullable<Evaluation> = row[ 0 ];
						if (isNull(evaluation)) return false;
						const coordinatorName = `${ evaluation.coordinatorUser.name } ${ evaluation.coordinatorUser.surname }`;
						return isNotNull(row) && !filters.map(filterElement => filterElement.toLowerCase()).includes(coordinatorName.toLowerCase());
					},
				},
				sort: true,
				customBodyRender: (evaluation: Evaluation) =>
					<div className="flex items-center gap-2">
						<Avatar
							alt={ `${ evaluation.coordinatorUser.name }-${ evaluation.coordinatorUser.surname }-avatar` }
							img={ evaluation.coordinatorUser.image?.icon }
							placeholderInitials={ `${ evaluation.coordinatorUser.name[ 0 ] }${ evaluation.coordinatorUser.surname[ 0 ] }` }
							size="sm"
						/>
						<span>{ `${ evaluation.coordinatorUser.name } ${ evaluation.coordinatorUser.surname }` }</span>
					</div>,
				sortCompare: order => (a: SortCompare<Evaluation>, b: SortCompare<Evaluation>) => {
					const coordinatorUserA = `${ a.data.coordinatorUser.name } ${ a.data.coordinatorUser.surname }`;
					const coordinatorUserB = `${ b.data.coordinatorUser.name } ${ b.data.coordinatorUser.surname }`;
					if (order === "asc") {
						return coordinatorUserA.localeCompare(coordinatorUserB);
					} else {
						return coordinatorUserB.localeCompare(coordinatorUserA);
					}
				},
			},
		}, {
			name: "Data utworzenia",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (evaluation: Evaluation) => DateTime.fromISO(evaluation.createdAt).toFormat("d MMM yyyy"),
				sortCompare: order => (a: SortCompare<Evaluation>, b: SortCompare<Evaluation>) => {
					if (order === "asc") {
						return DateTime.fromISO(a.data.createdAt).toMillis() - DateTime.fromISO(b.data.createdAt).toMillis();
					} else {
						return DateTime.fromISO(b.data.createdAt).toMillis() - DateTime.fromISO(a.data.createdAt).toMillis();
					}
				},
			},
		}, {
			name: "Akcje",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (evaluation: Evaluation) =>
					<div className="flex gap-2 items-center sm:justify-end">
						{/*<Tooltip content="Archiwizuj">
							<Button
								onClick={ e => {
									e.stopPropagation();
									setArchiveEvaluationModal({
										isOpen: true,
										value: evaluation,
									});
								} }
								color="transparent"
								size="sm"
								className="p-0 [&>span]:p-2"
							>
								<RiArchive2Fill className="text-primary-700 w-5 h-5"/>
							</Button>
						</Tooltip>*/ }
						<Tooltip content="Zmień PM'a">
							<Button
								onClick={ e => {
									e.stopPropagation();
									setChangeEvaluationCoordinatorModal({
										isOpen: true,
										value: evaluation,
									});
								} }
								color="transparent"
								size="sm"
								className="p-0 [&>span]:p-2"
							>
								<span><HiUsers className="text-primary-700 w-5 h-5"/></span>
							</Button>
						</Tooltip>
						{
							canDeleteEvaluation(evaluation) &&
                            <Tooltip content="Usuń badanie">
                                <Button
                                    onClick={ e => {
										e.stopPropagation();
										setDeleteEvaluationModal({
											isOpen: true,
											value: evaluation,
										});
									} }
                                    color="transparent"
                                    size="sm"
                                    className="p-0 [&>span]:p-2"
                                >
                                    <span><HiOutlineTrash className="text-primary-700 w-5 h-5"/></span>
                                </Button>
                            </Tooltip>
						}
					</div>,
				setCellHeaderProps: () => ({ style: { textAlign: "right" } }),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		customToolbar: () =>
			<>
				<Button
					className="order-first mr-3"
					size="sm"
					color="primary"
					onClick={ () => setCreateMassReportModal(prevState => ({ ...prevState, isOpen: true })) }
				>
					<span>Stwórz raport grupowy</span>
				</Button>
				{
					canLoggedUserCreateEvaluation(loggedUser) &&
                    <Link to={ getLink(`/evaluations/create`) } className="order-first mr-3">
                        <Button size="sm" color="primary"><span>Dodaj badanie</span></Button>
                    </Link>
				}
			</>,
		onRowClick: (rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => {
			const clickedEvaluation: Nullable<Evaluation> = props.evaluations[ rowMeta.dataIndex ];
			if (isNotNull(clickedEvaluation) && canViewEvaluation(clickedEvaluation)) {
				navigate(`/evaluations/${ clickedEvaluation.id }`);
			}
		},
		customSearch: (searchQuery: string, currentRow: Evaluation[]) => {
			searchQuery = searchQuery.toLowerCase();
			const row = currentRow[ 0 ];
			const name = row.name;
			const companyName = row.companyName;
			const status = evaluationStatusDictionary[ row.evaluationStatus ];
			const coordinatorUser = `${ row.coordinatorUser.name } ${ row.coordinatorUser.surname }`;

			if (name.toLowerCase().includes(searchQuery)) {
				return true;
			} else if (companyName.toLowerCase().includes(searchQuery)) {
				return true;
			} else if (status.toLowerCase().includes(searchQuery)) {
				return true;
			} else if (coordinatorUser.toLowerCase().includes(searchQuery)) {
				return true;
			}
			return false;
		},
	};

	const _handleConfirmArchiveEvaluation = () => {
		if (isNotNull(archiveEvaluationModal.value)) {
			onArchiveEvaluation(archiveEvaluationModal.value.id);
			setArchiveEvaluationModal(prevState => ({ ...prevState, isOpen: false }));
		}
	};
	const _handleConfirmDeleteEvaluation = () => {
		if (isNotNull(deleteEvaluationModal.value)) {
			onDeleteEvaluation(deleteEvaluationModal.value.id);
			setDeleteEvaluationModal(prevState => ({ ...prevState, isOpen: false }));
		}
	};
	const isDeletingEvaluation = isNotNull(deleteEvaluationModal.value) && isDeleting(deleteEvaluationModal.value.id);
	const isArchivingEvaluation = isNotNull(archiveEvaluationModal.value) && isArchiving(archiveEvaluationModal.value.id);

	return (
		<>
			<div className="flex flex-col gap-4">
				<div className="grid grid-cols-4 gap-4">
					<Card className="[&>div]:px-4 [&>div]:py-[18px]">
						<div className="flex gap-3">
							<div className="p-2 w-12 h-12 flex items-center justify-center bg-primary-200 rounded-lg">
								<BsFillTicketFill className="h-8 w-8 text-primary-800"/>
							</div>
							<div className="flex flex-col justify-between">
								<p className="font-bold text-2xl leading-none">{ evaluations.length }</p>
								<p className="text-gray-500">Wszystkich badań</p>
							</div>
						</div>
					</Card>
					<Card className="[&>div]:px-4 [&>div]:py-[18px]">
						<div className="flex gap-3">
							<div className="p-2 w-12 h-12 flex items-center justify-center bg-yellow-100 rounded-lg">
								<GoHourglass className="h-8 w-8 text-yellow-700"/>
							</div>
							<div className="flex flex-col justify-between">
								<p className="font-bold text-2xl leading-none">{ evaluations.filter(evaluation => evaluation.evaluationStatus === Status.STARTED).length }</p>
								<p className="text-gray-500">Rozpoczętych badań</p>
							</div>
						</div>
					</Card>
					<Card className="[&>div]:px-4 [&>div]:py-[18px]">
						<div className="flex gap-3">
							<div className="p-2 w-12 h-12 flex items-center justify-center bg-gray-100 rounded-lg">
								<FaFlagCheckered className="h-8 w-8 text-gray-700"/>
							</div>
							<div className="flex flex-col justify-between">
								<p className="font-bold text-2xl leading-none">{ evaluations.filter(evaluation => evaluation.evaluationStatus === Status.FINISHED).length }</p>
								<p className="text-gray-500">Zakończonych badań</p>
							</div>
						</div>
					</Card>
					<Card className="[&>div]:px-4 [&>div]:py-[18px]">
						<div className="flex gap-3">
							<div className="p-2 w-12 h-12 flex items-center justify-center bg-green-100 rounded-lg">
								<HiCheckCircle className="h-8 w-8 text-green-700"/>
							</div>
							<div className="flex flex-col justify-between">
								<p className="font-bold text-2xl leading-none">{ evaluations.filter(evaluation => evaluation.evaluationStatus === Status.PUBLISHED).length }</p>
								<p className="text-gray-500">Opublikowanych badań</p>
							</div>
						</div>
					</Card>
				</div>
				<Table
					title="Badania"
					columns={ evaluationColumns }
					options={ tableOptions }
					data={ evaluations }
				/>
			</div>
			<ConfirmModal
				title={ `Usuń ${ (isNotNull(deleteEvaluationModal.value) && !isEmptyString(deleteEvaluationModal.value.name)) ? deleteEvaluationModal.value.name : "badanie" }` }
				warning="Czy jesteś pewień? Ta operacja jest nieodwracalna"
				isOpen={ deleteEvaluationModal.isOpen }
				confirmWord="kasuj"
				isLoading={ isDeletingEvaluation }
				handleClose={ () => setDeleteEvaluationModal({ isOpen: false, value: null }) }
				onConfirm={ _handleConfirmDeleteEvaluation }
			/>
			<ConfirmModal
				title={ `Zaarchiwizuj ${ (isNotNull(deleteEvaluationModal.value) && !isEmptyString(deleteEvaluationModal.value.name)) ? deleteEvaluationModal.value.name : "badanie" }` }
				warning="Czy jesteś pewień? Ta operacja jest nieodwracalna"
				isOpen={ archiveEvaluationModal.isOpen }
				confirmWord="archiwizuj"
				confirmText="Archiwizuj"
				isLoading={ isArchivingEvaluation }
				handleClose={ () => setArchiveEvaluationModal({ isOpen: false, value: null }) }
				onConfirm={ _handleConfirmArchiveEvaluation }
			/>
			{
				isNotNull(changeEvaluationCoordinatorModal.value) &&
                <EditEvaluationCoordinatorUserModal
                    isOpen={ changeEvaluationCoordinatorModal.isOpen }
                    evaluation={ changeEvaluationCoordinatorModal.value }
                    handleClose={ () => setChangeEvaluationCoordinatorModal(prevState => ({ ...prevState, isOpen: false })) }
                    onChangeCoordinatorUser={ onChangeEvaluationCoordinatorUser }
                />
			}
			<CreateMassReportModal
				isOpen={ createMassReportModal.isOpen }
				evaluations={ evaluations }
				handleClose={ () => setCreateMassReportModal(prevState => ({ ...prevState, isOpen: false })) }
				onCreateMassReport={ onCreateMassReport }
			/>
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	isDeleting: (evaluationId: number) => didLoadingRecordExist(state, { loadableId: evaluationId, loadableType: LoadableType.DELETE_EVALUATION }),
	isArchiving: (evaluationId: number) => didLoadingRecordExist(state, { loadableId: evaluationId, loadableType: LoadableType.ARCHIVE_EVALUATION }),
	// mainHeight: state.ui.layout.mainSize.height,
});

export default connect(mapStateToProps)(EvaluationListContainer);

/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { RootEpic } from "src/app/store/root.epic";
import { isActionOf } from "typesafe-actions";
import { uiArchiveEvaluation, uiChangeEvaluationCoordinatorUser, uiCreateEvaluation, uiCreateMassReport, uiDeleteEvaluation, uiFinishEvaluation, uiGenerateEvaluationReport, uiPublishEvaluationReport, uiShareEvaluationToRepresentative, uiStartEvaluation, uiUndoShareEvaluationToRepresentative, uiUpdateEvaluation, uiUpdateEvaluationQuestions } from "src/app/store/features/ui/evaluation/ui.evaluation.actions";
import { filter, mergeMap, switchMap, take } from "rxjs/operators";
import { concat, merge, of } from "rxjs";
import { addLoadingRecord, removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LoadableType, LoadingRecord } from "src/app/types/ui/loading.types";
import { archiveEvaluationAsync, createEvaluationAsync, createMassReportAsync, deleteEvaluationByIdAsync, finishEvaluationAsync, generateEvaluationReportAsync, publishEvaluationReportAsync, shareEvaluationToRepresentativeAsync, startEvaluationAsync, undoShareEvaluationToRepresentativeAsync, updateEvaluationAsync } from "src/app/store/features/evaluation/evaluation.actions";
import { displayToast } from "src/app/store/features/message/message.actions";
import { ToastType } from "src/app/types/ui/message.types";
import { scopedPush } from "src/app/store/features/misc/misc.actions";

export const uiCreateEvaluationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCreateEvaluation)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableType: LoadableType.CREATE_EVALUATION };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(createEvaluationAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(createEvaluationAsync.success, action) || isActionOf(createEvaluationAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(createEvaluationAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie utworzono badanie!" })),
								of(scopedPush(`/evaluations/${ responseAction.payload.data.id }`)),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiUpdateEvaluationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUpdateEvaluation)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload.id, loadableType: LoadableType.UPDATE_EVALUATION };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(updateEvaluationAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateEvaluationAsync.success, action) || isActionOf(updateEvaluationAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateEvaluationAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie zaktualizowano badanie!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiUpdateEvaluationQuestionsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUpdateEvaluationQuestions)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload.id, loadableType: LoadableType.UPDATE_EVALUATION_QUESTIONS };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(updateEvaluationAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateEvaluationAsync.success, action) || isActionOf(updateEvaluationAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateEvaluationAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie zaktualizowano badanie!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiDeleteEvaluationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiDeleteEvaluation)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.DELETE_EVALUATION };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(deleteEvaluationByIdAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(deleteEvaluationByIdAsync.success, action) || isActionOf(deleteEvaluationByIdAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(deleteEvaluationByIdAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie usunięto badanie!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiChangeEvaluationCoordinatorUserEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiChangeEvaluationCoordinatorUser)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.CHANGE_EVALUATION_COORDINATOR_USER })),
				of(updateEvaluationAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateEvaluationAsync.success, action) || isActionOf(updateEvaluationAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateEvaluationAsync.success, responseAction)) {
							return merge(
								of(displayToast({ type: ToastType.SUCCESS, content: "Project Manager został pomyślnie zmieniony!" })),
								of(removeLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.CHANGE_EVALUATION_COORDINATOR_USER })),
							);
						} else {
							return of(removeLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.CHANGE_EVALUATION_COORDINATOR_USER }));
						}
					}),
				),
			),
		),
	);

export const uiArchiveEvaluationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiArchiveEvaluation)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableId: action.payload, loadableType: LoadableType.ARCHIVE_EVALUATION })),
				of(archiveEvaluationAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(archiveEvaluationAsync.success, action) || isActionOf(archiveEvaluationAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(archiveEvaluationAsync.success, responseAction)) {
							return merge(
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie zaarchiwizowano badanie!" })),
								of(removeLoadingRecord({ loadableId: action.payload, loadableType: LoadableType.ARCHIVE_EVALUATION })),
							);
						} else {
							return of(removeLoadingRecord({ loadableId: action.payload, loadableType: LoadableType.ARCHIVE_EVALUATION }));
						}
					}),
				),
			),
		),
	);

export const uiShareEvaluationToRepresentativeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiShareEvaluationToRepresentative)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableId: action.payload, loadableType: LoadableType.SHARE_EVALUATION_TO_REPRESENTATIVE })),
				of(shareEvaluationToRepresentativeAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(shareEvaluationToRepresentativeAsync.success, action) || isActionOf(shareEvaluationToRepresentativeAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(shareEvaluationToRepresentativeAsync.success, responseAction)) {
							return merge(
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie udostępniono badanie przedstawicielowi!" })),
								of(removeLoadingRecord({ loadableId: action.payload, loadableType: LoadableType.SHARE_EVALUATION_TO_REPRESENTATIVE })),
							);
						} else {
							return of(removeLoadingRecord({ loadableId: action.payload, loadableType: LoadableType.SHARE_EVALUATION_TO_REPRESENTATIVE }));
						}
					}),
				),
			),
		),
	);

export const uiUndoShareEvaluationToRepresentativeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUndoShareEvaluationToRepresentative)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableId: action.payload, loadableType: LoadableType.UNDO_SHARE_EVALUATION_TO_REPRESENTATIVE })),
				of(undoShareEvaluationToRepresentativeAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(undoShareEvaluationToRepresentativeAsync.success, action) || isActionOf(undoShareEvaluationToRepresentativeAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(undoShareEvaluationToRepresentativeAsync.success, responseAction)) {
							return merge(
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie odebrano dostęp przedstawiciela do panelu!" })),
								of(removeLoadingRecord({ loadableId: action.payload, loadableType: LoadableType.UNDO_SHARE_EVALUATION_TO_REPRESENTATIVE })),
							);
						} else {
							return of(removeLoadingRecord({ loadableId: action.payload, loadableType: LoadableType.UNDO_SHARE_EVALUATION_TO_REPRESENTATIVE }));
						}
					}),
				),
			),
		),
	);

export const uiStartEvaluationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiStartEvaluation)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.START_EVALUATION };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(startEvaluationAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(startEvaluationAsync.success, action) || isActionOf(startEvaluationAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(startEvaluationAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie wystartowano badanie!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiFinishEvaluationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFinishEvaluation)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.FINISH_EVALUATION };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(finishEvaluationAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(finishEvaluationAsync.success, action) || isActionOf(finishEvaluationAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(finishEvaluationAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie zakończono badanie!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiGenerateEvaluationReportEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiGenerateEvaluationReport)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.GENERATE_EVALUATION_REPORT };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(generateEvaluationReportAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(generateEvaluationReportAsync.success, action) || isActionOf(generateEvaluationReportAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(generateEvaluationReportAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie wygenerowano raport badania!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiPublishEvaluationReportEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiPublishEvaluationReport)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.PUBLISH_EVALUATION_REPORT };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(publishEvaluationReportAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(publishEvaluationReportAsync.success, action) || isActionOf(publishEvaluationReportAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(publishEvaluationReportAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie opublikowano raport badania!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiCreateMassReportEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCreateMassReport)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableType: LoadableType.CREATE_MASS_REPORT };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(createMassReportAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(createMassReportAsync.success, action) || isActionOf(createMassReportAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(createMassReportAsync.success, responseAction)) {
							const downloadLink = document.createElement("a");
							downloadLink.href = responseAction.payload.data.url;
							downloadLink.download = `${ action.payload }.icd`;

							document.body.appendChild(downloadLink);
							downloadLink.click();

							document.body.removeChild(downloadLink);

							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie stworzono raport grupowy!" })),
							);
						} else if (isActionOf(createMassReportAsync.failure, responseAction)) {
							const errorMessages = responseAction.payload.errors
																.map((error: { message: string }) => error.message)
																.join(", ");

							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.ERROR, content: errorMessages })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);
import { DetailedEvaluation, UpdateEvaluationPayload } from "src/app/types/api/evaluation.types";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateField } from "src/app/utils/forms";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import { Button, Card, Modal, ToggleSwitch } from "flowbite-react";
import TextArea from "src/app/components/Form/TextArea.component";
import Input from "src/app/components/Form/Input.component";

type ComponentProps = {
	isOpen: boolean
	handleClose: () => void
	evaluation: DetailedEvaluation
	onEdit: (payload: UpdateEvaluationPayload) => void
};

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

type EditEvaluationQuestionsForm = {
	openQuestion1: string
	openQuestion1En: string
	openQuestion2: string
	openQuestion2En: string
	participantCustomNameSubject: string
	participantCustomNameSubjectEn: string
	participantCustomNameSupervisor: string
	participantCustomNameSupervisorEn: string
	participantCustomNameSubordinate: string
	participantCustomNameSubordinateEn: string
	participantCustomNameColleague: string
	participantCustomNameColleagueEn: string
	participantCustomNameAdditionalRoleOne: string
	participantCustomNameAdditionalRoleOneEn: string
	participantCustomNameAdditionalRoleTwo: string
	participantCustomNameAdditionalRoleTwoEn: string
	shouldCountAverageForSupervisors: boolean
}

const validator: FormValidator<EditEvaluationQuestionsForm> = {
	openQuestion1: (openQuestion1, optional) => validateField(openQuestion1, "Pytanie otwarte nr 1 jest wymagane", optional),
	openQuestion1En: (openQuestion1En, optional) => validateField(openQuestion1En, "Pytanie otwarte nr 1 (ang) jest wymagane", optional),
	openQuestion2: (openQuestion2, optional) => validateField(openQuestion2, "Pytanie otwarte nr 2 jest wymagane", optional),
	openQuestion2En: (openQuestion2En, optional) => validateField(openQuestion2En, "Pytanie otwarte nr 2 (ang) jest wymagane", optional),

	participantCustomNameSubject: (participantCustomNameSubject, optional) => validateField(participantCustomNameSubject, "Nazwa dla osoby badanej", optional),
	participantCustomNameSubjectEn: (participantCustomNameSubjectEn, optional) => validateField(participantCustomNameSubjectEn, "Nazwa dla osoby badanej (ang)", optional),
	participantCustomNameSupervisor: (participantCustomNameSupervisor, optional) => validateField(participantCustomNameSupervisor, "Nazwa dla przełożonego", optional),
	participantCustomNameSupervisorEn: (participantCustomNameSupervisorEn, optional) => validateField(participantCustomNameSupervisorEn, "Nazwa dla przełożonego (ang)", optional),
	participantCustomNameSubordinate: (participantCustomNameSubordinate, optional) => validateField(participantCustomNameSubordinate, "Nazwa dla podwładnego", optional),
	participantCustomNameSubordinateEn: (participantCustomNameSubordinateEn, optional) => validateField(participantCustomNameSubordinateEn, "Nazwa dla podwładnego (ang)", optional),
	participantCustomNameColleague: (participantCustomNameColleague, optional) => validateField(participantCustomNameColleague, "Nazwa dla współpracownika", optional),
	participantCustomNameColleagueEn: (participantCustomNameColleagueEn, optional) => validateField(participantCustomNameColleagueEn, "Nazwa dla współpracownika (ang)", optional),
	participantCustomNameAdditionalRoleOne: (participantCustomNameAdditionalRoleOne, optional) => validateField(participantCustomNameAdditionalRoleOne, "Nazwa dla dodatkowej roli 1", optional),
	participantCustomNameAdditionalRoleOneEn: (participantCustomNameAdditionalRoleOneEn, optional) => validateField(participantCustomNameAdditionalRoleOneEn, "Nazwa dla dodatkowej roli 1 (ang)", optional),
	participantCustomNameAdditionalRoleTwo: (participantCustomNameAdditionalRoleTwo, optional) => validateField(participantCustomNameAdditionalRoleTwo, "Nazwa dla dodatkowej roli 2", optional),
	participantCustomNameAdditionalRoleTwoEn: (participantCustomNameAdditionalRoleTwoEn, optional) => validateField(participantCustomNameAdditionalRoleTwoEn, "Nazwa dla dodatkowej roli 2 (ang)", optional),
	shouldCountAverageForSupervisors: () => null,
};

function EditEvaluationQuestionsModal(props: Props) {

	const {
		isOpen,
		handleClose,
		evaluation,
		onEdit,
		isUpdating,
	} = props;

	const _handleSubmit = (values: EditEvaluationQuestionsForm) => {
		onEdit({
			id: evaluation.id,
			...values,
		});
		handleClose();
	};

	const _getInitialState = () => ({
		openQuestion1: createFormField(evaluation.openQuestion1 ?? ""),
		openQuestion1En: createFormField(evaluation.openQuestion1En ?? ""),
		openQuestion2: createFormField(evaluation.openQuestion2 ?? ""),
		openQuestion2En: createFormField(evaluation.openQuestion2En ?? ""),
		participantCustomNameSubject: createFormField(evaluation.participantCustomNameSubject ?? ""),
		participantCustomNameSubjectEn: createFormField(evaluation.participantCustomNameSubjectEn ?? ""),
		participantCustomNameSupervisor: createFormField(evaluation.participantCustomNameSupervisor ?? ""),
		participantCustomNameSupervisorEn: createFormField(evaluation.participantCustomNameSupervisorEn ?? ""),
		participantCustomNameSubordinate: createFormField(evaluation.participantCustomNameSubordinate ?? ""),
		participantCustomNameSubordinateEn: createFormField(evaluation.participantCustomNameSubordinateEn ?? ""),
		participantCustomNameColleague: createFormField(evaluation.participantCustomNameColleague ?? ""),
		participantCustomNameColleagueEn: createFormField(evaluation.participantCustomNameColleagueEn ?? ""),
		participantCustomNameAdditionalRoleOne: createFormField(evaluation.participantCustomNameAdditionalRoleOne ?? ""),
		participantCustomNameAdditionalRoleOneEn: createFormField(evaluation.participantCustomNameAdditionalRoleOneEn ?? ""),
		participantCustomNameAdditionalRoleTwo: createFormField(evaluation.participantCustomNameAdditionalRoleTwo ?? ""),
		participantCustomNameAdditionalRoleTwoEn: createFormField(evaluation.participantCustomNameAdditionalRoleTwoEn ?? ""),
		shouldCountAverageForSupervisors: createFormField(evaluation.shouldCountAverageForSupervisors),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ evaluation ]);

	useEffect(() => {
		if (!isOpen && !isUpdating) {
			setForm(_getInitialState());
		}
	}, [ isOpen, isUpdating ]);

	return (
		<Modal
			show={ isOpen || isUpdating }
			onClose={ handleClose }
			size="7xl"
			root={ document.body }
			key={ (isOpen || isUpdating) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				{ `Edytuj badanie: ${ evaluation.name }` }
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="overflow-visible">
					<div className="grid grid-cols-12 gap-4">
						<div className="col-span-6">
							<Card>
								<h3 className="text-[20px] font-semibold">Pytania</h3>
								<TextArea
									formItem={ form.openQuestion1 }
									label="Pytanie otwarte nr 1"
									name="openQuestion1"
									inputProps={ {
										onChange: (e) => handleChange("openQuestion1", e.target.value),
										onBlur: () => handleBlur("openQuestion1"),
										rows: 5,
										className: "min-h-[42px] max-h-60",
									} }
								/>
								<TextArea
									formItem={ form.openQuestion1En }
									label="Pytanie otwarte nr 1 (ang)"
									name="openQuestion1En"
									inputProps={ {
										onChange: (e) => handleChange("openQuestion1En", e.target.value),
										onBlur: () => handleBlur("openQuestion1En"),
										rows: 5,
										className: "min-h-[42px] max-h-60",
									} }
								/>
								<TextArea
									formItem={ form.openQuestion2 }
									label="Pytanie otwarte nr 2"
									name="openQuestion2"
									inputProps={ {
										onChange: (e) => handleChange("openQuestion2", e.target.value),
										onBlur: () => handleBlur("openQuestion2"),
										rows: 5,
										className: "min-h-[42px] max-h-60",
									} }
								/>
								<TextArea
									formItem={ form.openQuestion2En }
									label="Pytanie otwarte nr 2 (ang)"
									name="openQuestion2En"
									inputProps={ {
										onChange: (e) => handleChange("openQuestion2En", e.target.value),
										onBlur: () => handleBlur("openQuestion2En"),
										rows: 5,
										className: "min-h-[42px] max-h-60",
									} }
								/>
							</Card>
						</div>
						<div className="col-span-6">
							<Card>
								<h3 className="text-[20px] font-semibold">Raport</h3>
								<div className="flex gap-4 justify-between">
									<Input
										className="flex-1"
										formItem={ form.participantCustomNameSubject }
										label="Nazwa dla osoby badanej"
										name="participantCustomNameSubject"
										inputProps={ {
											type: "text",
											onChange: (e) => handleChange("participantCustomNameSubject", e.target.value),
											onBlur: () => handleBlur("participantCustomNameSubject"),
										} }
									/>
									<Input
										className="flex-1"
										formItem={ form.participantCustomNameSubjectEn }
										label="Nazwa dla osoby badanej (ang)"
										name="participantCustomNameSubjectEn"
										inputProps={ {
											type: "text",
											onChange: (e) => handleChange("participantCustomNameSubjectEn", e.target.value),
											onBlur: () => handleBlur("participantCustomNameSubjectEn"),
										} }
									/>
								</div>
								<div className="flex gap-4 justify-between">
									<Input
										className="flex-1"
										formItem={ form.participantCustomNameSupervisor }
										label="Nazwa dla przełożonego"
										name="participantCustomNameSupervisor"
										inputProps={ {
											type: "text",
											onChange: (e) => handleChange("participantCustomNameSupervisor", e.target.value),
											onBlur: () => handleBlur("participantCustomNameSupervisor"),
										} }
									/>
									<Input
										className="flex-1"
										formItem={ form.participantCustomNameSupervisorEn }
										label="Nazwa dla przełożonego (ang)"
										name="participantCustomNameSupervisorEn"
										inputProps={ {
											type: "text",
											onChange: (e) => handleChange("participantCustomNameSupervisorEn", e.target.value),
											onBlur: () => handleBlur("participantCustomNameSupervisorEn"),
										} }
									/>
								</div>
								<div className="flex gap-4 justify-between">
									<Input
										className="flex-1"
										formItem={ form.participantCustomNameSubordinate }
										label="Nazwa dla podwładnego"
										name="participantCustomNameSubordinate"
										inputProps={ {
											type: "text",
											onChange: (e) => handleChange("participantCustomNameSubordinate", e.target.value),
											onBlur: () => handleBlur("participantCustomNameSubordinate"),
										} }
									/>
									<Input
										className="flex-1"
										formItem={ form.participantCustomNameSubordinateEn }
										label="Nazwa dla podwładnego (ang)"
										name="participantCustomNameSubordinateEn"
										inputProps={ {
											type: "text",
											onChange: (e) => handleChange("participantCustomNameSubordinateEn", e.target.value),
											onBlur: () => handleBlur("participantCustomNameSubordinateEn"),
										} }
									/>
								</div>
								<div className="flex gap-4 justify-between">
									<Input
										className="flex-1"
										formItem={ form.participantCustomNameColleague }
										label="Nazwa dla współpracownika"
										name="participantCustomNameColleague"
										inputProps={ {
											type: "text",
											onChange: (e) => handleChange("participantCustomNameColleague", e.target.value),
											onBlur: () => handleBlur("participantCustomNameColleague"),
										} }
									/>
									<Input
										className="flex-1"
										formItem={ form.participantCustomNameColleagueEn }
										label="Nazwa dla współpracownika (ang)"
										name="participantCustomNameColleagueEn"
										inputProps={ {
											type: "text",
											onChange: (e) => handleChange("participantCustomNameColleagueEn", e.target.value),
											onBlur: () => handleBlur("participantCustomNameColleagueEn"),
										} }
									/>
								</div>
								<div className="flex gap-4 justify-between">
									<Input
										className="flex-1"
										formItem={ form.participantCustomNameAdditionalRoleOne }
										label="Nazwa dla dodatkowej roli 1"
										name="participantCustomNameAdditionalRoleOne"
										inputProps={ {
											type: "text",
											onChange: (e) => handleChange("participantCustomNameAdditionalRoleOne", e.target.value),
											onBlur: () => handleBlur("participantCustomNameAdditionalRoleOne"),
										} }
									/>
									<Input
										className="flex-1"
										formItem={ form.participantCustomNameAdditionalRoleOneEn }
										label="Nazwa dla dodatkowej roli 1 (ang)"
										name="participantCustomNameAdditionalRoleOneEn"
										inputProps={ {
											type: "text",
											onChange: (e) => handleChange("participantCustomNameAdditionalRoleOneEn", e.target.value),
											onBlur: () => handleBlur("participantCustomNameAdditionalRoleOneEn"),
										} }
									/>
								</div>
								<div className="flex gap-4 justify-between">
									<Input
										className="flex-1"
										formItem={ form.participantCustomNameAdditionalRoleTwo }
										label="Nazwa dla dodatkowej roli 2"
										name="participantCustomNameAdditionalRoleTwo"
										inputProps={ {
											type: "text",
											onChange: (e) => handleChange("participantCustomNameAdditionalRoleTwo", e.target.value),
											onBlur: () => handleBlur("participantCustomNameAdditionalRoleTwo"),
										} }
									/>
									<Input
										className="flex-1"
										formItem={ form.participantCustomNameAdditionalRoleTwoEn }
										label="Nazwa dla dodatkowej roli 2 (ang)"
										name="participantCustomNameAdditionalRoleTwoEn"
										inputProps={ {
											type: "text",
											onChange: (e) => handleChange("participantCustomNameAdditionalRoleTwoEn", e.target.value),
											onBlur: () => handleBlur("participantCustomNameAdditionalRoleTwoEn"),
										} }
									/>
								</div>
								<ToggleSwitch
									label="Zliczanie średnich dla przełożonych"
									checked={ form.shouldCountAverageForSupervisors.value }
									onChange={ () => handleChange("shouldCountAverageForSupervisors", !form.shouldCountAverageForSupervisors.value) }
								/>
							</Card>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-end border-none pt-0">
					<Button
						onClick={ handleClose }
						color="gray"
					>
						<span>Anuluj</span>
					</Button>
					<Button
						type="submit"
						isProcessing={ isUpdating }
					>
						<span>Zapisz</span>
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState, props: ComponentProps) => ({
	isUpdating: didLoadingRecordExist(state, { loadableId: props.evaluation.id, loadableType: LoadableType.UPDATE_EVALUATION_QUESTIONS }),
});

export default connect(mapStateToProps)(EditEvaluationQuestionsModal);

import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { fetchEvaluationsAsync } from "src/app/store/features/evaluation/evaluation.actions";
import EvaluationListContainer from "src/app/containers/Evaluation/EvaluationList.container";
import { uiArchiveEvaluation, uiChangeEvaluationCoordinatorUser, uiCreateMassReport, uiDeleteEvaluation } from "src/app/store/features/ui/evaluation/ui.evaluation.actions";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function EvaluationListView(props: Props) {
	const {
		evaluations,
		fetchEvaluations,
		archiveEvaluation,
		changeEvaluationCoordinatorUser,
		deleteEvaluation,
		createMassReport,
	} = props;

	return (
		<CachedThenFreshStrategy
			request={ fetchEvaluations }
			state={ evaluations }
		>
			{
				evaluations =>
					<EvaluationListContainer
						evaluations={ evaluations }
						onArchiveEvaluation={ archiveEvaluation }
						onChangeEvaluationCoordinatorUser={ changeEvaluationCoordinatorUser }
						onDeleteEvaluation={ deleteEvaluation }
						onCreateMassReport={ createMassReport }
					/>
			}
		</CachedThenFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	evaluations: state.evaluation.evaluations,
});

const mapDispatchToProps = {
	fetchEvaluations: fetchEvaluationsAsync.request,
	archiveEvaluation: uiArchiveEvaluation,
	changeEvaluationCoordinatorUser: uiChangeEvaluationCoordinatorUser,
	deleteEvaluation: uiDeleteEvaluation,
	createMassReport: uiCreateMassReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationListView);

import { CreateEvaluationPayload, EvaluationLanguage, EvaluationScheduledStartType, EvaluationType } from "src/app/types/api/evaluation.types";
import { Nullable } from "src/app/types/util.types";
import { SimpleUser } from "src/app/types/api/user.types";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateEmail, validateField, validateFutureDate, validateNullableField, validatePhone } from "src/app/utils/forms";
import BreadcrumbPortal from "src/app/components/Utils/BreadcrumbPortal.component";
import { Button, Card, Label, Radio, ToggleSwitch } from "flowbite-react";
import { HiArrowLeft, HiCheck } from "react-icons/hi";
import useUserScopeNavigate from "src/app/utils/hooks/useUserScopeNavigate";
import { Link } from "react-router-dom";
import useForm from "src/app/utils/hooks/useForm";
import Input from "src/app/components/Form/Input.component";
import { evaluationTypeDescriptionDictionary, evaluationTypeDictionary } from "src/app/utils/constants/dictionaries";
import Select from "src/app/components/Form/Select.component";
import { evaluationLanguageOptions, evaluationScheduledStartTypeOptions } from "src/app/utils/constants/constants";
import TextArea from "src/app/components/Form/TextArea.component";
import UserSelect from "src/app/components/Form/UserSelect.component";
import PhoneInput from "src/app/components/Form/PhoneInput.component";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import DatePicker from "src/app/components/Form/DatePicker.component";
import { isEmptyString, isNotNull, isNull } from "src/app/utils/typeguards";
import { DateTime } from "luxon";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		onCreateEvaluation: (payload: CreateEvaluationPayload) => void
	};

type CreateEvaluationForm = {
	name: string
	evaluationType: EvaluationType
	companyName: string
	evaluationLanguage: Nullable<EvaluationLanguage>
	evaluationScheduledStartType: EvaluationScheduledStartType
	evaluationScheduledStart: Nullable<Date>
	openQuestion1: string
	openQuestion1En: string
	openQuestion2: string
	openQuestion2En: string
	note: string
	coordinatorUser: Nullable<SimpleUser>
	subjectName: string
	subjectEmail: string
	representativeName: string
	representativePosition: string
	representativeEmail: string
	representativePhone: string
	representativeFormattedPhone: string
	representativePanelAccess: boolean

	participantCustomNameSubject: string
	participantCustomNameSubjectEn: string
	participantCustomNameSupervisor: string
	participantCustomNameSupervisorEn: string
	participantCustomNameSubordinate: string
	participantCustomNameSubordinateEn: string
	participantCustomNameColleague: string
	participantCustomNameColleagueEn: string
	participantCustomNameAdditionalRoleOne: string
	participantCustomNameAdditionalRoleOneEn: string
	participantCustomNameAdditionalRoleTwo: string
	participantCustomNameAdditionalRoleTwoEn: string
	shouldCountAverageForSupervisors: boolean
}

const validator: FormValidator<CreateEvaluationForm> = {
	name: (name, optional) => validateField(name, "Nazwa jest wymagana", optional),
	evaluationType: () => null,
	companyName: (companyName, optional) => validateField(companyName, "Nazwa firmy jest wymagana", optional),
	evaluationLanguage: (evaluationLanguage, optional) => validateNullableField(evaluationLanguage, "Język badania jest wymagany", optional),
	evaluationScheduledStartType: () => null,
	evaluationScheduledStart: (evaluationScheduledStart, optional, form) => {
		if (form.evaluationScheduledStartType.value !== EvaluationScheduledStartType.SCHEDULED_AT) return null;

		return validateFutureDate(evaluationScheduledStart, "Data startu jest wymagana", "Data jest niepoprawna", "Data jest przeszła", optional);
	},
	openQuestion1: (openQuestion1, optional) => validateField(openQuestion1, "Pytanie otwarte nr 1 jest wymagane", optional),
	openQuestion1En: (openQuestion1En, optional) => validateField(openQuestion1En, "Pytanie otwarte nr 1 (ang) jest wymagane", optional),
	openQuestion2: (openQuestion2, optional) => validateField(openQuestion2, "Pytanie otwarte nr 2 jest wymagane", optional),
	openQuestion2En: (openQuestion2En, optional) => validateField(openQuestion2En, "Pytanie otwarte nr 2 (ang) jest wymagane", optional),
	note: (note, optional) => validateField(note, "Notatka jest wymagana", optional),
	coordinatorUser: (coordinatorUser, optional) => validateNullableField(coordinatorUser, "Osoba prowadząca jest wymagana", optional),
	subjectName: (subjectName, optional) => validateField(subjectName, "Imię i nazwisko są wymagane", optional),
	subjectEmail: validateEmail,
	representativeName: (representativeName, optional) => validateField(representativeName, "Imię i nazwisko są wymagane", optional),
	representativePosition: (representativePosition, optional) => validateField(representativePosition, "Stanowisko jest wymagane", optional),
	representativeEmail: validateEmail,
	representativePhone: validatePhone,
	representativeFormattedPhone: () => null,
	representativePanelAccess: () => null,

	participantCustomNameSubject: (participantCustomNameSubject, optional) => validateField(participantCustomNameSubject, "Nazwa dla osoby badanej", optional),
	participantCustomNameSubjectEn: (participantCustomNameSubjectEn, optional) => validateField(participantCustomNameSubjectEn, "Nazwa dla osoby badanej (ang)", optional),
	participantCustomNameSupervisor: (participantCustomNameSupervisor, optional) => validateField(participantCustomNameSupervisor, "Nazwa dla przełożonego", optional),
	participantCustomNameSupervisorEn: (participantCustomNameSupervisorEn, optional) => validateField(participantCustomNameSupervisorEn, "Nazwa dla przełożonego (ang)", optional),
	participantCustomNameSubordinate: (participantCustomNameSubordinate, optional) => validateField(participantCustomNameSubordinate, "Nazwa dla podwładnego", optional),
	participantCustomNameSubordinateEn: (participantCustomNameSubordinateEn, optional) => validateField(participantCustomNameSubordinateEn, "Nazwa dla podwładnego (ang)", optional),
	participantCustomNameColleague: (participantCustomNameColleague, optional) => validateField(participantCustomNameColleague, "Nazwa dla współpracownika", optional),
	participantCustomNameColleagueEn: (participantCustomNameColleagueEn, optional) => validateField(participantCustomNameColleagueEn, "Nazwa dla współpracownika (ang)", optional),
	participantCustomNameAdditionalRoleOne: (participantCustomNameAdditionalRoleOne, optional) => validateField(participantCustomNameAdditionalRoleOne, "Nazwa dla dodatkowej roli 1", optional),
	participantCustomNameAdditionalRoleOneEn: (participantCustomNameAdditionalRoleOneEn, optional) => validateField(participantCustomNameAdditionalRoleOneEn, "Nazwa dla dodatkowej roli 1 (ang)", optional),
	participantCustomNameAdditionalRoleTwo: (participantCustomNameAdditionalRoleTwo, optional) => validateField(participantCustomNameAdditionalRoleTwo, "Nazwa dla dodatkowej roli 2", optional),
	participantCustomNameAdditionalRoleTwoEn: (participantCustomNameAdditionalRoleTwoEn, optional) => validateField(participantCustomNameAdditionalRoleTwoEn, "Nazwa dla dodatkowej roli 2 (ang)", optional),
	shouldCountAverageForSupervisors: () => null,
};

function EvaluationCreateView(props: Props) {

	const {
		onCreateEvaluation,
		isCreating,
	} = props;

	const { getLink, forceNavigate } = useUserScopeNavigate();

	const _handleSubmit = (values: CreateEvaluationForm) => {
		if (isNull(values.coordinatorUser) || isNull(values.evaluationLanguage)) return;

		onCreateEvaluation({
			coordinatorUserId: values.coordinatorUser.id,
			name: isEmptyString(values.name) ? null : values.name,
			evaluationType: values.evaluationType,
			companyName: values.companyName,
			evaluationLanguage: values.evaluationLanguage,
			evaluationScheduledStart: (values.evaluationScheduledStartType === EvaluationScheduledStartType.SCHEDULED_AT && isNotNull(values.evaluationScheduledStart)) ? values.evaluationScheduledStart.toISOString() : null,
			representativePanelAccess: values.representativePanelAccess,
			openQuestion1: values.openQuestion1,
			openQuestion1En: values.openQuestion1En,
			openQuestion2: values.openQuestion2,
			openQuestion2En: values.openQuestion2En,
			subjectName: values.subjectName,
			subjectEmail: values.subjectEmail,
			representativeName: values.representativeName,
			representativePosition: values.representativePosition,
			representativeEmail: values.representativeEmail,
			representativePhone: values.representativeFormattedPhone,
			note: values.note,
			participantCustomNameSubject: values.participantCustomNameSubject,
			participantCustomNameSubjectEn: values.participantCustomNameSubjectEn,
			participantCustomNameSupervisor: values.participantCustomNameSupervisor,
			participantCustomNameSupervisorEn: values.participantCustomNameSupervisorEn,
			participantCustomNameSubordinate: values.participantCustomNameSubordinate,
			participantCustomNameSubordinateEn: values.participantCustomNameSubordinateEn,
			participantCustomNameColleague: values.participantCustomNameColleague,
			participantCustomNameColleagueEn: values.participantCustomNameColleagueEn,
			participantCustomNameAdditionalRoleOne: values.participantCustomNameAdditionalRoleOne,
			participantCustomNameAdditionalRoleOneEn: values.participantCustomNameAdditionalRoleOneEn,
			participantCustomNameAdditionalRoleTwo: values.participantCustomNameAdditionalRoleTwo,
			participantCustomNameAdditionalRoleTwoEn: values.participantCustomNameAdditionalRoleTwoEn,
			shouldCountAverageForSupervisors: values.shouldCountAverageForSupervisors,
		});
	};

	const {
		form,
		handleChange,
		handleBlur,
		toggleDisable,
		handleSubmit,
	} = useForm({
		name: createFormField("", { optional: true }),
		evaluationType: createFormField(EvaluationType.MPI),
		companyName: createFormField(""),
		evaluationLanguage: createFormField(null),
		evaluationScheduledStartType: createFormField(EvaluationScheduledStartType.MANUAL),
		evaluationScheduledStart: createFormField(null, { disabled: true }),
		openQuestion1: createFormField("Za co najbardziej cenisz opisywaną osobę?"),
		openQuestion1En: createFormField("What do you value the most described person for?"),
		openQuestion2: createFormField("Jakie zachowania/umiejętności powinna Twoim zdaniem rozwijać/zmienić opisywana osoba?"),
		openQuestion2En: createFormField("What behavior/skills should described person develop?"),
		note: createFormField("", { optional: true }),
		coordinatorUser: createFormField(null),
		subjectName: createFormField(""),
		subjectEmail: createFormField(""),
		representativeName: createFormField(""),
		representativePosition: createFormField("", { optional: true }),
		representativeEmail: createFormField(""),
		representativePhone: createFormField("", { optional: true }),
		representativeFormattedPhone: createFormField(""),
		representativePanelAccess: createFormField(true),
		participantCustomNameSubject: createFormField("Osoba badana"),
		participantCustomNameSubjectEn: createFormField("Subject"),
		participantCustomNameSupervisor: createFormField("Przełożony"),
		participantCustomNameSupervisorEn: createFormField("Supervisor"),
		participantCustomNameSubordinate: createFormField("Podwładny"),
		participantCustomNameSubordinateEn: createFormField("Subordinate"),
		participantCustomNameColleague: createFormField("Współpracownik"),
		participantCustomNameColleagueEn: createFormField("Colleague"),
		participantCustomNameAdditionalRoleOne: createFormField("Dodatkowa Rola 1"),
		participantCustomNameAdditionalRoleOneEn: createFormField("Additional Role 1"),
		participantCustomNameAdditionalRoleTwo: createFormField("Dodatkowa Rola 2"),
		participantCustomNameAdditionalRoleTwoEn: createFormField("Additional Role 2"),
		shouldCountAverageForSupervisors: createFormField(true),
	}, validator, _handleSubmit);

	return (
		<form onSubmit={ handleSubmit }>
			<BreadcrumbPortal>
				<div className="flex justify-between gap-2 items-center">
					<Link
						to={ getLink("/evaluations") }
						className="self-end flex items-center gap-2 cursor-pointer"
						onClick={ e => {
							e.preventDefault();
							forceNavigate("/evaluations");
						} }
					>
						<HiArrowLeft className="w-5 h-5"/>
						<span className="text-sm font-medium">Wróć do badań</span>
					</Link>
					<Button
						type="submit"
						isProcessing={ isCreating }
						onClick={ () => handleSubmit() }
					>
						<span><HiCheck className="mr-2 w-4 h-4"/></span>
						<span>Zapisz</span>
					</Button>
				</div>
			</BreadcrumbPortal>
			<div className="grid grid-cols-12 gap-4">
				<div className="col-span-7 flex flex-col gap-4">
					<Card>
						<Input
							formItem={ form.name }
							label="Nazwa badania (opcjonalnie)"
							name="name"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("name", e.target.value),
								onBlur: () => handleBlur("name"),
								helperText: <>Pozostaw puste aby automatycznie wygenerować nazwę</>,
							} }
						/>
						<fieldset className="flex flex-col gap-2.5">
							<Label>
								Rodzaj badania
							</Label>
							<div className="flex gap-10">
								<div className="flex items-start gap-2">
									<Radio
										id="mpi"
										name="mpi"
										onChange={ () => {
											handleChange("evaluationType", EvaluationType.MPI);
											handleBlur("evaluationType");
										} }
										checked={ form.evaluationType.value === EvaluationType.MPI }
									/>
									<Label htmlFor="mpi" className="flex flex-col gap-1.5">
										<p className="!leading-[1.125] text-sm font-medium">
											{ evaluationTypeDictionary[ EvaluationType.MPI ] }
										</p>
										<p className="!leading-none text-xs text-gray-500">
											{ evaluationTypeDescriptionDictionary[ EvaluationType.MPI ] }
										</p>
									</Label>
								</div>
								<div className="flex items-start gap-2">
									<Radio
										id="lpi"
										name="lpi"
										onChange={ () => {
											handleChange("evaluationType", EvaluationType.LPI);
											handleBlur("evaluationType");
										} }
										checked={ form.evaluationType.value === EvaluationType.LPI }
									/>
									<Label htmlFor="lpi" className="flex flex-col gap-1.5">
										<p className="!leading-[1.125] text-sm font-medium">
											{ evaluationTypeDictionary[ EvaluationType.LPI ] }
										</p>
										<p className="!leading-none text-xs text-gray-500">
											{ evaluationTypeDescriptionDictionary[ EvaluationType.LPI ] }
										</p>
									</Label>
								</div>
							</div>
						</fieldset>
						<Input
							formItem={ form.companyName }
							label="Nazwa firmy"
							name="companyName"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("companyName", e.target.value),
								onBlur: () => handleBlur("companyName"),
							} }
						/>
						<Select
							label="Język badania"
							options={ evaluationLanguageOptions }
							formItem={ form.evaluationLanguage }
							isClearable={ false }
							isSearchable={ false }
							onChange={ option => {
								handleChange("evaluationLanguage", option?.value ?? null);
								handleBlur("evaluationLanguage");
							} }
						/>
						<div className="flex gap-4 justify-between">
							<Select
								className="flex-1"
								label="Start badania"
								options={ evaluationScheduledStartTypeOptions }
								formItem={ form.evaluationScheduledStartType }
								isClearable={ false }
								isSearchable={ false }
								onChange={ option => {
									handleChange("evaluationScheduledStartType", option?.value ?? null);
									handleBlur("evaluationScheduledStartType");

									if (option?.value !== EvaluationScheduledStartType.SCHEDULED_AT) handleChange("evaluationScheduledStart", null);

									toggleDisable("evaluationScheduledStart", option?.value !== EvaluationScheduledStartType.SCHEDULED_AT);
								} }
							/>
							<DatePicker
								className="flex-1"
								label="Data startu badania"
								formItem={ form.evaluationScheduledStart }
								datepickerProps={ {
									minDate: DateTime.now().toJSDate(),
								} }
								onChange={ date => handleChange("evaluationScheduledStart", date) }
								onBlur={ () => handleBlur("evaluationScheduledStart") }
							/>
						</div>
						<TextArea
							formItem={ form.note }
							label="Notatka"
							name="note"
							inputProps={ {
								onChange: (e) => handleChange("note", e.target.value),
								onBlur: () => handleBlur("note"),
								rows: 5,
								className: "min-h-[42px] max-h-60",
							} }
						/>
					</Card>
					<Card>
						<h2 className="font-bold">
							Pytania otwarte
						</h2>
						<TextArea
							formItem={ form.openQuestion1 }
							label="Pytanie otwarte nr 1"
							name="openQuestion1"
							inputProps={ {
								onChange: (e) => handleChange("openQuestion1", e.target.value),
								onBlur: () => handleBlur("openQuestion1"),
								rows: 5,
								className: "min-h-[42px] max-h-60",
							} }
						/>
						<TextArea
							formItem={ form.openQuestion1En }
							label="Pytanie otwarte nr 1 (ang)"
							name="openQuestion1En"
							inputProps={ {
								onChange: (e) => handleChange("openQuestion1En", e.target.value),
								onBlur: () => handleBlur("openQuestion1En"),
								rows: 5,
								className: "min-h-[42px] max-h-60",
							} }
						/>
						<TextArea
							formItem={ form.openQuestion2 }
							label="Pytanie otwarte nr 2"
							name="openQuestion2"
							inputProps={ {
								onChange: (e) => handleChange("openQuestion2", e.target.value),
								onBlur: () => handleBlur("openQuestion2"),
								rows: 5,
								className: "min-h-[42px] max-h-60",
							} }
						/>
						<TextArea
							formItem={ form.openQuestion2En }
							label="Pytanie otwarte nr 2 (ang)"
							name="openQuestion2En"
							inputProps={ {
								onChange: (e) => handleChange("openQuestion2En", e.target.value),
								onBlur: () => handleBlur("openQuestion2En"),
								rows: 5,
								className: "min-h-[42px] max-h-60",
							} }
						/>
					</Card>
				</div>
				<div className="col-span-5 flex flex-col gap-4">
					<Card>
						<h2 className="font-bold">
							Project Manager
						</h2>
						<UserSelect
							formItem={ form.coordinatorUser }
							onChange={ user => {
								handleChange("coordinatorUser", user);
								handleBlur("coordinatorUser");
							} }
						/>
					</Card>
					<Card>
						<h2 className="font-bold">
							Osoba badana
						</h2>
						<div className="flex gap-4 justify-between">
							<Input
								className="flex-1"
								formItem={ form.subjectName }
								label="Imię i nazwisko osoby badanej"
								name="subjectName"
								inputProps={ {
									type: "text",
									onChange: (e) => handleChange("subjectName", e.target.value),
									onBlur: () => handleBlur("subjectName"),
								} }
							/>
							<Input
								className="flex-1"
								formItem={ form.subjectEmail }
								label="Adres e-mail"
								name="subjectEmail"
								inputProps={ {
									type: "email",
									onChange: (e) => handleChange("subjectEmail", e.target.value),
									onBlur: () => handleBlur("subjectEmail"),
								} }
							/>
						</div>
					</Card>
					<Card>
						<h2 className="font-bold">
							Reprezentant firmy klienta
						</h2>
						<div className="flex gap-4 justify-between">
							<Input
								className="flex-1"
								formItem={ form.representativeName }
								label="Imię i nazwisko reprezentanta"
								name="representativeName"
								inputProps={ {
									type: "text",
									onChange: (e) => handleChange("representativeName", e.target.value),
									onBlur: () => handleBlur("representativeName"),
								} }
							/>
							<Input
								className="flex-1"
								formItem={ form.representativePosition }
								label="Stanowisko (opcjonalnie)"
								name="representativePosition"
								inputProps={ {
									type: "text",
									onChange: (e) => handleChange("representativePosition", e.target.value),
									onBlur: () => handleBlur("representativePosition"),
								} }
							/>
						</div>
						<div className="flex gap-4 justify-between">
							<Input
								className="flex-1"
								formItem={ form.representativeEmail }
								label="Adres e-mail"
								name="representativeEmail"
								inputProps={ {
									type: "email",
									onChange: (e) => handleChange("representativeEmail", e.target.value),
									onBlur: () => handleBlur("representativeEmail"),
								} }
							/>
							<PhoneInput
								className="flex-1"
								label="Telefon kontaktowy"
								name="phone"
								phoneInputProps={ {
									country: "pl",
									onEnterKeyPress: () => handleSubmit(),
								} }
								formItem={ form.representativePhone }
								onChange={ (phone, formattedPhone) => {
									handleChange("representativePhone", phone);
									handleChange("representativeFormattedPhone", formattedPhone);
								} }
								onBlur={ () => handleBlur("representativePhone") }
							/>
						</div>
						<ToggleSwitch
							label="Włącz logowanie dla reprezentanta klienta"
							checked={ form.representativePanelAccess.value }
							onChange={ () => handleChange("representativePanelAccess", !form.representativePanelAccess.value) }
						/>
					</Card>
					<Card>
						<h2 className="font-bold">
							Raport
						</h2>
						<div className="flex flex-col gap-4">
							<div className="flex gap-4 justify-between">
								<Input
									className="flex-1"
									formItem={ form.participantCustomNameSubject }
									label="Nazwa dla osoby badanej"
									name="participantCustomNameSubject"
									inputProps={ {
										type: "text",
										onChange: (e) => handleChange("participantCustomNameSubject", e.target.value),
										onBlur: () => handleBlur("participantCustomNameSubject"),
									} }
								/>
								<Input
									className="flex-1"
									formItem={ form.participantCustomNameSubjectEn }
									label="Nazwa dla osoby badanej (ang)"
									name="participantCustomNameSubjectEn"
									inputProps={ {
										type: "text",
										onChange: (e) => handleChange("participantCustomNameSubjectEn", e.target.value),
										onBlur: () => handleBlur("participantCustomNameSubjectEn"),
									} }
								/>
							</div>
							<div className="flex gap-4 justify-between">
								<Input
									className="flex-1"
									formItem={ form.participantCustomNameSupervisor }
									label="Nazwa dla przełożonego"
									name="participantCustomNameSupervisor"
									inputProps={ {
										type: "text",
										onChange: (e) => handleChange("participantCustomNameSupervisor", e.target.value),
										onBlur: () => handleBlur("participantCustomNameSupervisor"),
									} }
								/>
								<Input
									className="flex-1"
									formItem={ form.participantCustomNameSupervisorEn }
									label="Nazwa dla przełożonego (ang)"
									name="participantCustomNameSupervisorEn"
									inputProps={ {
										type: "text",
										onChange: (e) => handleChange("participantCustomNameSupervisorEn", e.target.value),
										onBlur: () => handleBlur("participantCustomNameSupervisorEn"),
									} }
								/>
							</div>
							<div className="flex gap-4 justify-between">
								<Input
									className="flex-1"
									formItem={ form.participantCustomNameSubordinate }
									label="Nazwa dla podwładnego"
									name="participantCustomNameSubordinate"
									inputProps={ {
										type: "text",
										onChange: (e) => handleChange("participantCustomNameSubordinate", e.target.value),
										onBlur: () => handleBlur("participantCustomNameSubordinate"),
									} }
								/>
								<Input
									className="flex-1"
									formItem={ form.participantCustomNameSubordinateEn }
									label="Nazwa dla podwładnego (ang)"
									name="participantCustomNameSubordinateEn"
									inputProps={ {
										type: "text",
										onChange: (e) => handleChange("participantCustomNameSubordinateEn", e.target.value),
										onBlur: () => handleBlur("participantCustomNameSubordinateEn"),
									} }
								/>
							</div>
							<div className="flex gap-4 justify-between">
								<Input
									className="flex-1"
									formItem={ form.participantCustomNameColleague }
									label="Nazwa dla współpracownika"
									name="participantCustomNameColleague"
									inputProps={ {
										type: "text",
										onChange: (e) => handleChange("participantCustomNameColleague", e.target.value),
										onBlur: () => handleBlur("participantCustomNameColleague"),
									} }
								/>
								<Input
									className="flex-1"
									formItem={ form.participantCustomNameColleagueEn }
									label="Nazwa dla współpracownika (ang)"
									name="participantCustomNameColleagueEn"
									inputProps={ {
										type: "text",
										onChange: (e) => handleChange("participantCustomNameColleagueEn", e.target.value),
										onBlur: () => handleBlur("participantCustomNameColleagueEn"),
									} }
								/>
							</div>
							<div className="flex gap-4 justify-between">
								<Input
									className="flex-1"
									formItem={ form.participantCustomNameAdditionalRoleOne }
									label="Nazwa dla dodatkowej roli 1"
									name="participantCustomNameAdditionalRoleOne"
									inputProps={ {
										type: "text",
										onChange: (e) => handleChange("participantCustomNameAdditionalRoleOne", e.target.value),
										onBlur: () => handleBlur("participantCustomNameAdditionalRoleOne"),
									} }
								/>
								<Input
									className="flex-1"
									formItem={ form.participantCustomNameAdditionalRoleOneEn }
									label="Nazwa dla dodatkowej roli 1 (ang)"
									name="participantCustomNameAdditionalRoleOneEn"
									inputProps={ {
										type: "text",
										onChange: (e) => handleChange("participantCustomNameAdditionalRoleOneEn", e.target.value),
										onBlur: () => handleBlur("participantCustomNameAdditionalRoleOneEn"),
									} }
								/>
							</div>
							<div className="flex gap-4 justify-between">
								<Input
									className="flex-1"
									formItem={ form.participantCustomNameAdditionalRoleTwo }
									label="Nazwa dla dodatkowej roli 2"
									name="participantCustomNameAdditionalRoleTwo"
									inputProps={ {
										type: "text",
										onChange: (e) => handleChange("participantCustomNameAdditionalRoleTwo", e.target.value),
										onBlur: () => handleBlur("participantCustomNameAdditionalRoleTwo"),
									} }
								/>
								<Input
									className="flex-1"
									formItem={ form.participantCustomNameAdditionalRoleTwoEn }
									label="Nazwa dla dodatkowej roli 2 (ang)"
									name="participantCustomNameAdditionalRoleTwoEn"
									inputProps={ {
										type: "text",
										onChange: (e) => handleChange("participantCustomNameAdditionalRoleTwoEn", e.target.value),
										onBlur: () => handleBlur("participantCustomNameAdditionalRoleTwoEn"),
									} }
								/>
							</div>
							<ToggleSwitch
								label="Zliczanie średnich dla przełożonych"
								checked={ form.shouldCountAverageForSupervisors.value }
								onChange={ () => handleChange("shouldCountAverageForSupervisors", !form.shouldCountAverageForSupervisors.value) }
							/>
						</div>
					</Card>
				</div>
			</div>
		</form>
	);
}

const mapStateToProps = (state: RootState) => ({
	isCreating: didLoadingRecordExist(state, { loadableType: LoadableType.CREATE_EVALUATION }),
});

export default connect(mapStateToProps)(EvaluationCreateView);

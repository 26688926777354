import { CreateMassReportPayload, Evaluation, EvaluationStatus, EvaluationType } from "src/app/types/api/evaluation.types";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateField, validateNullableField } from "src/app/utils/forms";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import useForm from "src/app/utils/hooks/useForm";
import React, { useEffect, useState } from "react";
import { Button, Label, Modal, Radio } from "flowbite-react";
import { evaluationTypeDescriptionDictionary, evaluationTypeDictionary, surveyLanguageDictionary } from "src/app/utils/constants/dictionaries";
import { SurveyLanguage } from "src/app/types/api/participant.types";
import { ModalConfig } from "src/app/types/util.types";
import ChooseEvaluationsModal from "src/app/components/Evaluation/ChooseEvaluationsModal.component";
import { Close } from "@material-ui/icons";
import ChooseEvaluationsToTakeAverageModal from "src/app/components/Evaluation/ChooseEvaluationsToTakeAverageModal.component";
import EvaluationButton from "src/app/components/Utils/EvaluationButton";

type ComponentProps = {
	isOpen: boolean
	handleClose: () => void
	onCreateMassReport: (payload: CreateMassReportPayload) => void
	evaluations: Evaluation[]
};

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

type EditEvaluationQuestionsForm = {
	evaluationType: EvaluationType
	evaluationIds: number[]
	evaluationIdsToTakeAverage: number[]
	surveyLanguage: SurveyLanguage
}

export const MINIMUM_EVALUATIONS_AMOUNT = 2;
export const MAXIMUM_EVALUATIONS_AMOUNT = 15;

const validator: FormValidator<EditEvaluationQuestionsForm> = {
	evaluationType: (evaluationType, optional) => validateField(evaluationType, "Typ badania jest wymagany", optional),
	evaluationIds: (evaluationIds, optional) => {

		if (evaluationIds.length < MINIMUM_EVALUATIONS_AMOUNT) {
			return "Wymagane są conajmniej 2 badania";
		}
		if (evaluationIds.length > MAXIMUM_EVALUATIONS_AMOUNT) {
			return `Maksymalnie można wybrać ${ MAXIMUM_EVALUATIONS_AMOUNT } badań`;
		}

		validateNullableField(evaluationIds, "Typ badania jest wymagany", optional);
	},
	evaluationIdsToTakeAverage: (evaluationIdsToTakeAverage, optional) => {
		if (evaluationIdsToTakeAverage.length < MINIMUM_EVALUATIONS_AMOUNT) {
			return "Wymagane są conajmniej 2 badania do średniej";
		}

		validateNullableField(evaluationIdsToTakeAverage, "Typ badania jest wymagany", optional);
	},
	surveyLanguage: (surveyLanguage, optional) => validateField(surveyLanguage, "Język jest wymagany", optional),
};

function CreateMassReportModal(props: Props) {

	const {
		isOpen,
		handleClose,
		isCreating,
		evaluations,
		onCreateMassReport,
	} = props;

	const _handleSubmit = (values: EditEvaluationQuestionsForm) => {

		onCreateMassReport({
			evaluationType: values.evaluationType,
			evaluationIds: values.evaluationIds,
			evaluationIdsToTakeAverage: values.evaluationIdsToTakeAverage,
			surveyLanguage: values.surveyLanguage,
		});

		handleClose();
	};

	const _getInitialState = () => ({
		evaluationType: createFormField(EvaluationType.MPI),
		evaluationIds: createFormField([]),
		evaluationIdsToTakeAverage: createFormField([]),
		surveyLanguage: createFormField(SurveyLanguage.POLISH),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ evaluations ]);

	useEffect(() => {
		if (!isOpen && !isCreating) {
			setForm(_getInitialState());
		}
	}, [ isOpen, isCreating ]);

	const [ selectableEvaluations, setSelectedEvaluations ] = useState<Evaluation[]>(evaluations.filter(evaluation => evaluation.evaluationType === form.evaluationType.value));
	useEffect(() => {
		setSelectedEvaluations(
			evaluations.filter(evaluation => {
					return evaluation.evaluationType === form.evaluationType.value && evaluation.evaluationStatus !== EvaluationStatus.CREATED;
				},
			));
		handleChange("evaluationIds", []);
		handleChange("evaluationIdsToTakeAverage", []);
	}, [ form.evaluationType.value ]);

	const [ chooseEvaluationModal, setChooseEvaluationModal ] = useState<ModalConfig<Evaluation>>({
		isOpen: false,
		value: null,
	});

	const [ chooseEvaluationToTakeAverageModal, setChooseEvaluationToTakeAverageModal ] = useState<ModalConfig<Evaluation>>({
		isOpen: false,
		value: null,
	});

	return (
		<>
			<Modal
				show={ isOpen || isCreating }
				onClose={ handleClose }
				size="7xl"
				root={ document.body }
				key={ (isOpen || isCreating) ? "open" : "hidden" } // AutoFocus on input work with this
			>
				<Modal.Header>
					Stwórz raport grupowy
				</Modal.Header>
				<form onSubmit={ handleSubmit }>
					<Modal.Body className="overflow-visible">
						<Label>
							Rodzaj badania
						</Label>
						<div className="flex gap-10 mb-3 mt-2">
							<div className="flex items-start gap-2">
								<Radio
									id="mpi"
									name="mpi"
									onChange={ () => {
										handleChange("evaluationType", EvaluationType.MPI);
										handleBlur("evaluationType");
									} }
									checked={ form.evaluationType.value === EvaluationType.MPI }
								/>
								<Label htmlFor="mpi" className="flex flex-col gap-1.5">
									<p className="!leading-[1.125] text-sm font-medium">
										{ evaluationTypeDictionary[ EvaluationType.MPI ] }
									</p>
									<p className="!leading-none text-xs text-gray-500">
										{ evaluationTypeDescriptionDictionary[ EvaluationType.MPI ] }
									</p>
								</Label>
							</div>
							<div className="flex items-start gap-2">
								<Radio
									id="lpi"
									name="lpi"
									onChange={ () => {
										handleChange("evaluationType", EvaluationType.LPI);
										handleBlur("evaluationType");
									} }
									checked={ form.evaluationType.value === EvaluationType.LPI }
								/>
								<Label htmlFor="lpi" className="flex flex-col gap-1.5">
									<p className="!leading-[1.125] text-sm font-medium">
										{ evaluationTypeDictionary[ EvaluationType.LPI ] }
									</p>
									<p className="!leading-none text-xs text-gray-500">
										{ evaluationTypeDescriptionDictionary[ EvaluationType.LPI ] }
									</p>
								</Label>
							</div>
						</div>
						<Label>
							Język ankiety
						</Label>
						<div className="flex gap-10 mb-5 mt-2">
							<div className="flex items-start gap-2">
								<Radio
									id="polish"
									name="polish"
									onChange={ () => {
										handleChange("surveyLanguage", SurveyLanguage.POLISH);
										handleBlur("surveyLanguage");
									} }
									checked={ form.surveyLanguage.value === SurveyLanguage.POLISH }
								/>
								<Label htmlFor="polish" className="flex flex-col gap-1.5">
									<p className="!leading-[1.125] text-sm font-medium">
										{ surveyLanguageDictionary[ SurveyLanguage.POLISH ] }
									</p>
								</Label>
							</div>
							<div className="flex items-start gap-2">
								<Radio
									id="english"
									name="english"
									onChange={ () => {
										handleChange("surveyLanguage", SurveyLanguage.ENGLISH);
										handleBlur("surveyLanguage");
									} }
									checked={ form.surveyLanguage.value === SurveyLanguage.ENGLISH }
								/>
								<Label htmlFor="english" className="flex flex-col gap-1.5">
									<p className="!leading-[1.125] text-sm font-medium">
										{ surveyLanguageDictionary[ SurveyLanguage.ENGLISH ] }
									</p>
								</Label>
							</div>
						</div>

						<Label>
							Badania
						</Label>
						<div className="flex flex-row flex-wrap gap-2 mt-2 mb-3 items-center">
							{
								form.evaluationIds.value.length === 0 &&
                                <span>Nie wybrano</span>
							}
							{
								form.evaluationIds.value.map((evaluation) => {
										return (
											<EvaluationButton
												onClick={ () => {
													handleChange("evaluationIds", form.evaluationIds.value.filter(filterEvaluation => !(filterEvaluation === evaluation)));
													handleBlur("evaluationIds");
												} }
											>
												{ evaluations.find(e => e.id === evaluation)?.name }
												<Close/>
											</EvaluationButton>
										);
									},
								)
							}
							{
								form.evaluationIds.value.length !== 0 &&
                                <EvaluationButton
                                    onClick={ () => {
										handleChange("evaluationIds", []);
										handleBlur("evaluationIds");
									} }
                                >
                                    Usuń wszystkie
                                </EvaluationButton>
							}
						</div>
						{
							form.evaluationIds.error &&
                            <span className="text-red-600 text-sm mb-2 block">{ form.evaluationIds.error }</span>
						}
						<Button
							onClick={ () => setChooseEvaluationModal({ isOpen: true, value: null }) }
							className="mb-3"
						>
							Dodaj badania
						</Button>

						<Label>
							Badania do średniej
						</Label>
						<div className="flex flex-row flex-wrap gap-2 mt-2 mb-3 items-center">
							{
								form.evaluationIdsToTakeAverage.value.length === 0 &&
                                <span>Nie wybrano</span>
							}
							{
								form.evaluationIdsToTakeAverage.value.map((evaluation) => {
										if (form.evaluationIdsToTakeAverage.value.length <= 15) {
											return (
												<EvaluationButton
													onClick={ () => {
														handleChange("evaluationIdsToTakeAverage", form.evaluationIdsToTakeAverage.value.filter(filterEvaluation => !(filterEvaluation === evaluation)));
														handleBlur("evaluationIdsToTakeAverage");
													} }
												>
													{ evaluations.find(e => e.id === evaluation)?.name }
													<Close/>
												</EvaluationButton>
											);
										} else if (form.evaluationIdsToTakeAverage.value.length === 16) {
											return <div className="flex items-end">...</div>;
										}
									},
								)
							}
							{
								form.evaluationIdsToTakeAverage.value.length !== 0 &&
                                <EvaluationButton
                                    onClick={ () => {
										handleChange("evaluationIdsToTakeAverage", []);
										handleBlur("evaluationIdsToTakeAverage");
									} }
                                >
                                    Usuń wszystkie
                                </EvaluationButton>
							}
						</div>
						{
							form.evaluationIdsToTakeAverage.error &&
                            <span className="text-red-600 text-sm mb-2 block">{ form.evaluationIdsToTakeAverage.error }</span>
						}
						<Button
							onClick={ () => setChooseEvaluationToTakeAverageModal({ isOpen: true, value: null }) }
							className="mb-3"
						>
							Dodaj badania do średniej
						</Button>

					</Modal.Body>
					<Modal.Footer className="flex justify-end border-none pt-0">
						<Button
							onClick={ handleClose }
							color="gray"
						>
							<span>Anuluj</span>
						</Button>
						<Button
							type="submit"
							isProcessing={ isCreating }
						>
							<span>Stwórz</span>
						</Button>
					</Modal.Footer>
				</form>
			</Modal>
			<ChooseEvaluationsModal
				isOpen={ chooseEvaluationModal.isOpen }
				handleClose={ () => setChooseEvaluationModal({ isOpen: false, value: null }) }
				handleClearIds={ () => handleChange("evaluationIds", []) }
				form={ form }
				handleChange={ handleChange }
				handleBlur={ handleBlur }
				evaluations={ selectableEvaluations }
			/>
			<ChooseEvaluationsToTakeAverageModal
				isOpen={ chooseEvaluationToTakeAverageModal.isOpen }
				handleClose={ () => setChooseEvaluationToTakeAverageModal({ isOpen: false, value: null }) }
				handleClearIds={ () => handleChange("evaluationIdsToTakeAverage", []) }
				form={ form }
				handleChange={ handleChange }
				handleBlur={ handleBlur }
				evaluations={ selectableEvaluations }
			/>
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	isCreating: didLoadingRecordExist(state, { loadableType: LoadableType.CREATE_MASS_REPORT }),
});

export default connect(mapStateToProps)(CreateMassReportModal);

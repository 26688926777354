import { DetailedEvaluation, UpdateEvaluationPayload } from "src/app/types/api/evaluation.types";
import { Alert, Badge, Button, Card, Checkbox, Tooltip } from "flowbite-react";
import { HiArrowLeft, HiArrowRight, HiCheckCircle, HiOutlineDuplicate, HiOutlineTrash, HiPencil, HiPlus } from "react-icons/hi";
import LabelValue from "src/app/components/Utils/LabelValue.component";
import { Link } from "react-router-dom";
import BreadcrumbPortal from "src/app/components/Utils/BreadcrumbPortal.component";
import useUserScopeNavigate from "src/app/utils/hooks/useUserScopeNavigate";
import { isEmptyString, isNotNull, isNull } from "src/app/utils/typeguards";
import { DateTime } from "luxon";
import EditEvaluationModal from "src/app/components/Evaluation/EditEvaluationModal.component";
import { useContext, useEffect, useState } from "react";
import { CreateParticipantPayload, ParticipantSurveyStatus as Status, ParticipantType, SendReminderToParticipantsPayload, SimpleParticipant, SurveyLanguage, UpdateParticipantPayload } from "src/app/types/api/participant.types";
import CreateParticipantModal from "src/app/components/Evaluation/Participant/CreateParticipantModal.component";
import { evaluationTypeDictionary, participantSurveyStatusDictionary, participantTypeDictionary } from "src/app/utils/constants/dictionaries";
import ParticipantSurveyStatus from "src/app/components/Evaluation/Participant/ParticipantSurveyStatus.component";
import { LuRedo2 } from "react-icons/lu";
import { RiShareForwardFill, RiSurveyFill } from "react-icons/ri";
import ConfirmModal from "src/app/components/Utils/ConfirmModal.component";
import { ModalConfig } from "src/app/types/util.types";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { connect } from "react-redux";
import { LoadableType } from "src/app/types/ui/loading.types";
import EditParticipantModal from "src/app/components/Evaluation/Participant/EditParticipantModal.component";
import UserAvatar from "src/app/components/User/UserAvatar.component";
import EvaluationStatus from "src/app/components/Evaluation/EvaluationStatus.component";
import { canAddOrDeleteEvaluationParticipant, canFinishEvaluation, canGenerateEvaluationReport, canMassRemindEvaluationParticipants, canPublishEvaluationReport } from "src/app/utils/abilities";
import { FaFileImport, FaFlagCheckered } from "react-icons/fa6";
import ImportParticipantsModal from "src/app/components/Evaluation/Participant/ImportParticipantsModal.component";
import { BiWorld } from "react-icons/bi";
import { baseUrl } from "src/app/utils/constants/constants";
import { UserScopeContext } from "src/app/hoc/providers/UserScope.provider";
import EditEvaluationQuestionsModal from "src/app/components/Evaluation/EditEvaluationQuestionsModal.component";
import { useCollapse } from "react-collapsed";
import { ImUndo } from "react-icons/im";
import classNames from "classnames";

type ComponentProps = {
	evaluation: DetailedEvaluation
	onUpdateEvaluation: (payload: UpdateEvaluationPayload) => void
	onUpdateEvaluationQuestions: (payload: UpdateEvaluationPayload) => void
	onStartEvaluation: () => void
	onFinishEvaluation: () => void
	onGenerateEvaluationReport: () => void
	onPublishEvaluationReport: () => void
	onCreateParticipant: (payload: CreateParticipantPayload) => void
	onUpdateParticipant: (payload: UpdateParticipantPayload) => void
	onSendReminder: (payload: SendReminderToParticipantsPayload) => void
	onSendReminderToSubject: (payload: SendReminderToParticipantsPayload) => void
	onShareEvaluationToRepresentative: (evaluationId: number) => void
	onUndoShareEvaluationToRepresentative: (evaluationId: number) => void
	onImportParticipants: (file: File) => void
	onDeleteParticipant: (participantId: number) => void
}

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

function EvaluationProfileContainer(props: Props) {

	const {
		evaluation,
		onUpdateEvaluation,
		onUpdateEvaluationQuestions,
		onStartEvaluation,
		onFinishEvaluation,
		onGenerateEvaluationReport,
		onPublishEvaluationReport,
		onCreateParticipant,
		onUpdateParticipant,
		onSendReminder,
		onSendReminderToSubject,
		onShareEvaluationToRepresentative,
		onUndoShareEvaluationToRepresentative,
		onImportParticipants,
		onDeleteParticipant,
		isStarting,
		isFinishing,
		isGeneratingReport,
		isPublishingReport,
		isSendingReminder,
		isSendingReminderToSubject,
		isSharing,
		isUndoSharing,
		isDeleting,
	} = props;

	const userScope = useContext(UserScopeContext);

	const { getLink, navigate, forceNavigate } = useUserScopeNavigate();

	const [ isCreateParticipantModalOpen, toggleCreateParticipantModal ] = useState(false);
	const [ isImportParticipantsModalOpen, toggleImportParticipantsModal ] = useState(false);
	const [ isEditModalOpen, toggleEditModal ] = useState(false);
	const [ isEditQuestionsModalOpen, toggleEditQuestionsModal ] = useState(false);
	const [ updateParticipantModal, toggleUpdateParticipantModal ] = useState<ModalConfig<SimpleParticipant>>({
		isOpen: false,
		value: null,
	});

	const [ isShareModalOpen, toggleShareModal ] = useState(false);
	const [ isUndoShareModalOpen, toggleUndoShareModal ] = useState(false);
	const [ deleteParticipantModal, setDeleteParticipantModal ] = useState<ModalConfig<SimpleParticipant>>({
		isOpen: false,
		value: null,
	});

	const [ selectedParticipants, setSelectedParticipants ] = useState<number[]>([]);

	const [ isExpanded, toggleExpanded ] = useState(true);
	const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

	const _handleConfirmDeleteParticipant = () => {
		if (isNotNull(deleteParticipantModal.value)) {
			onDeleteParticipant(deleteParticipantModal.value.id);
			setDeleteParticipantModal(prevState => ({ ...prevState, isOpen: false }));
		}
	};
	const isDeletingParticipant = isNotNull(deleteParticipantModal.value) && isDeleting(deleteParticipantModal.value.id);

	useEffect(() => {
		const ids = evaluation.participants.map(participant => participant.id);
		if (!selectedParticipants.every(participantId => ids.includes(participantId))) {
			setSelectedParticipants(prevState => prevState.filter(id => ids.includes(id)));
		}
	}, [ evaluation.participants.length ]);

	useEffect(() => {
		if (!isSendingReminder && selectedParticipants.length > 0) {
			setSelectedParticipants([]);
		}
	}, [ isSendingReminder ]);

	const subjectParticipant = evaluation.participants.find(participant => participant.participantType === ParticipantType.SUBJECT);

	const [ isCopied, toggleCopied ] = useState(false);

	const _handleCopyToClipboard = () => {
		const data = [ new ClipboardItem({ "text/plain": new Blob([ `${ window.location.protocol }//${ window.location.host }/route/preview-evaluation?evaluationId=${ evaluation.id }&email=${ evaluation.representativeEmail }&token=${ evaluation.representativeAccessToken }` ], { type: "text/plain" }) }) ];
		navigator.clipboard.write(data);
		toggleCopied(true);
	};

	return (
		<>
			<BreadcrumbPortal>
				<Link
					to={ getLink("/evaluations") }
					className="mt-4 flex items-center gap-2 cursor-pointer"
					onClick={ e => {
						e.preventDefault();
						forceNavigate("/evaluations");
					} }
				>
					<HiArrowLeft className="w-5 h-5"/>
					<span className="text-sm font-medium">Wróć do badań</span>
				</Link>
			</BreadcrumbPortal>
			<div className="grid grid-cols-2 gap-4">
				<div className="flex flex-col gap-4">
					<Card>
						<div className="flex gap-4 flex-col">
							<div className="flex gap-2 justify-between pb-2.5">
								<div className="flex gap-2 items-center">
									<h2 className="text-2xl font-semibold leading-none">{ evaluation.name }</h2>
									<EvaluationStatus status={ evaluation.evaluationStatus }/>
								</div>
								<div className="flex gap-2.5">
									<Button
										onClick={ () => toggleEditModal(true) }
										color="transparent"
										size="sm"
										className="p-0 [&>span]:p-2"
									>
										<span><HiPencil className="text-primary-700 w-5 h-5"/></span>
									</Button>
								</div>
							</div>
							<div className="flex flex-col gap-2 justify-center items-center py-4 border-b border-t border-gray-100">
								{
									isNull(evaluation.evaluationScheduledStart) ?
										(
											isNull(evaluation.startedAt) ?
												<Button
													color="primary"
													onClick={ onStartEvaluation }
													isProcessing={ isStarting }
												>
													<span>Rozpocznij badanie</span>
													<span><HiArrowRight className="ml-2 w-4 h-4"/></span>
												</Button>
												:
												<>
													<span className="text-lg text-gray-500">
														{ `Rozpoczęto: ${ DateTime.fromISO(evaluation.startedAt).toFormat("dd.MM.yyyy, T") }` }
													</span>
													{
														canFinishEvaluation(evaluation) &&
                                                        <Button
                                                            color="primary"
                                                            onClick={ onFinishEvaluation }
                                                            isProcessing={ isFinishing }
                                                        >
                                                            <span>Zakończ badanie</span>
                                                            <span><FaFlagCheckered className="ml-2 w-4 h-4"/></span>
                                                        </Button>
													}
												</>
										)
										:
										(
											isNull(evaluation.startedAt) ?
												<span className="text-lg text-gray-500">
													{ `Planowany start: ${ DateTime.fromISO(evaluation.evaluationScheduledStart).toFormat("dd.MM.yyyy, T") }` }
												</span>
												:
												<>
													<span className="text-lg text-gray-500">
														{ `Rozpoczęto: ${ DateTime.fromISO(evaluation.startedAt).toFormat("dd.MM.yyyy, T") }` }
													</span>
													{
														canFinishEvaluation(evaluation) &&
                                                        <Button
                                                            color="primary"
                                                            onClick={ onFinishEvaluation }
                                                            isProcessing={ isFinishing }
                                                        >
                                                            <span>Zakończ badanie</span>
                                                            <span><FaFlagCheckered className="ml-2 w-4 h-4"/></span>
                                                        </Button>
													}
												</>
										)
								}
								{
									isNotNull(evaluation.endedAt) &&
                                    <span className="text-lg text-gray-500">
										{ `Zakończono: ${ DateTime.fromISO(evaluation.endedAt).toFormat("dd.MM.yyyy, T") }` }
									</span>
								}
							</div>
							<div className="grid grid-cols-2 gap-2">
								<div className="flex flex-col gap-3">
									<div className="flex items-center justify-between">
										<h3 className="text-lg font-semibold border-b-2 border-primary-400">Klient</h3>
									</div>
									<LabelValue
										label="Nazwa firmy"
										value={ evaluation.companyName }
									/>
									<LabelValue
										label="Badana osoba"
										value={ evaluation.subjectName }
									/>
									<div className="flex items-center justify-between">
										<h3 className="text-lg font-semibold mt-2 border-b-2 border-primary-400">Ogólne</h3>
									</div>
									<LabelValue
										label="Rodzaj badania"
										value={ evaluationTypeDictionary[ evaluation.evaluationType ] }
									/>
									<LabelValue
										label="Project Manager"
										value={
											<UserAvatar
												user={ evaluation.coordinatorUser }
											/>
										}
									/>
								</div>
								<div className="flex flex-col gap-3">
									<div className="flex items-center justify-between gap-2">
										<h3 className="text-lg font-semibold border-b-2 border-primary-400">Przedstawiciel klienta</h3>
										{
											evaluation.representativePanelAccess ?
												<Button
													onClick={ () => toggleUndoShareModal(true) }
													color="transparent"
													size="sm"
													className="p-0 [&>span]:p-2"
												>
													<span>Cofnij dostęp</span>
													<span><ImUndo className="text-primary-700 w-4 h-4 ml-2"/></span>
												</Button>
												:
												<Button
													onClick={ () => toggleShareModal(true) }
													color="transparent"
													size="sm"
													className="p-0 [&>span]:p-2"
												>
													<span>Nadaj dostęp</span>
													<span><RiShareForwardFill className="text-primary-700 w-4 h-4 ml-2"/></span>
												</Button>
										}
									</div>
									<LabelValue
										label="Imię i nazwisko"
										value={ evaluation.representativeName }
									/>
									<div className="flex gap-2 items-center">
										<LabelValue
											label="Dostęp do panelu"
											value={
												evaluation.representativePanelAccess ?
													<Badge className="w-fit" color="success">
														Tak
													</Badge>
													:
													<Badge className="w-fit" color="failure">
														Nie
													</Badge>
											}
										/>
										{
											(evaluation.representativePanelAccess && isNotNull(evaluation.representativeAccessToken)) &&
                                            <Tooltip
                                                content={ isCopied ? "Skopiowano!" : "Skopiuj link" }
                                            >
                                                <Button
                                                    onClick={ _handleCopyToClipboard }
                                                    onMouseLeave={ () => toggleCopied(false) }
                                                    color="transparent"
                                                    size="sm"
                                                    className="p-0 [&>span]:p-2"
                                                >
                                                    <span><HiOutlineDuplicate className="text-primary-700 w-5 h-5"/></span>
                                                </Button>
                                            </Tooltip>
										}
									</div>
									<LabelValue
										label="Adres e-mail"
										value={
											<a
												className="underline hover:no-underline"
												target="_blank"
												href={ `mailto:${ evaluation.representativeEmail }` }
											>
												{ evaluation.representativeEmail }
											</a>
										}
									/>
									<LabelValue
										label="Telefon kontaktowy"
										value={ evaluation.representativePhone }
									/>
								</div>
							</div>
							{
								!isEmptyString(evaluation.note) &&
                                <LabelValue
                                    label="Notatka"
                                    value={ evaluation.note }
                                />
							}
						</div>
					</Card>
					<Card className="[&>div]:p-0 [&>div]:gap-0">
						<div
							className="flex items-center justify-between p-6 pb-4"
							{ ...getToggleProps({ onClick: () => toggleExpanded(prevState => !prevState) }) }
						>
							<h3 className="text-[20px] font-semibold">Treści</h3>
							<Button
								onClick={ (e) => {
									e.stopPropagation();
									toggleEditQuestionsModal(true);
								} }
								color="transparent"
								size="sm"
								className="p-0 [&>span]:p-2"
							>
								<span><HiPencil className="text-primary-700 w-5 h-5"/></span>
							</Button>
						</div>
						<div { ...getCollapseProps() }>
							<div className="px-6 pb-6">
								<div className="flex flex-col gap-2">
									<LabelValue
										label="Pytanie otwarte nr 1"
										value={ evaluation.openQuestion1 }
									/>
									<LabelValue
										label="Pytanie otwarte nr 1 (ang)"
										value={ evaluation.openQuestion1En }
									/>
									<LabelValue
										label="Pytanie otwarte nr 2"
										value={ evaluation.openQuestion2 }
									/>
									<LabelValue
										label="Pytanie otwarte nr 2 (ang)"
										value={ evaluation.openQuestion2En }
									/>
									<div className="flex justify-between mt-3 pt-4 border-t border-gray-100">
										<div className="flex-1">
											<LabelValue
												label="Nazwa dla osoby badanej (ang)"
												value={ evaluation.participantCustomNameSubject }
											/>
										</div>
										<div className="flex-1">
											<LabelValue
												label="Nazwa dla osoby badanej (ang)"
												value={ evaluation.participantCustomNameSubjectEn }
											/>
										</div>
									</div>
									<div className="flex justify-between">
										<div className="flex-1">
											<LabelValue
												label="Nazwa dla przełożonego"
												value={ evaluation.participantCustomNameSupervisor }
											/>
										</div>
										<div className="flex-1">
											<LabelValue
												label="Nazwa dla przełożonego (ang)"
												value={ evaluation.participantCustomNameSupervisorEn }
											/>
										</div>
									</div>
									<div className="flex justify-between">
										<div className="flex-1">
											<LabelValue
												label="Nazwa dla podwładnego"
												value={ evaluation.participantCustomNameSubordinate }
											/>
										</div>
										<div className="flex-1">
											<LabelValue
												label="Nazwa dla podwładnego (ang)"
												value={ evaluation.participantCustomNameSubordinateEn }
											/>
										</div>
									</div>
									<div className="flex justify-between">
										<div className="flex-1">
											<LabelValue
												label="Nazwa dla współpracownika"
												value={ evaluation.participantCustomNameColleague }
											/>
										</div>
										<div className="flex-1">
											<LabelValue
												label="Nazwa dla współpracownika (ang)"
												value={ evaluation.participantCustomNameColleagueEn }
											/>
										</div>
									</div>
									<div className="flex justify-between">
										<div className="flex-1">
											<LabelValue
												label="Nazwa dla dodatkowej roli 1"
												value={ evaluation.participantCustomNameAdditionalRoleOne }
											/>
										</div>
										<div className="flex-1">
											<LabelValue
												label="Nazwa dla dodatkowej roli 1 (ang)"
												value={ evaluation.participantCustomNameAdditionalRoleOneEn }
											/>
										</div>
									</div>
									<div className="flex justify-between">
										<div className="flex-1">
											<LabelValue
												label="Nazwa dla dodatkowej roli 2"
												value={ evaluation.participantCustomNameAdditionalRoleTwo }
											/>
										</div>
										<div className="flex-1">
											<LabelValue
												label="Nazwa dla dodatkowej roli 2 (ang)"
												value={ evaluation.participantCustomNameAdditionalRoleTwoEn }
											/>
										</div>
									</div>
									<LabelValue
										label="Zliczanie średnich dla przełożonych"
										value={ evaluation.shouldCountAverageForSupervisors ? "Tak" : "Nie" }
									/>
								</div>
							</div>
						</div>
					</Card>
				</div>
				<div className="flex flex-col gap-4">
					<Card className="[&>div]:pb-16">
						<h3 className="text-[20px] font-semibold">Raport</h3>
						<div className="flex gap-2 justify-center items-center py-4 border-b border-t border-gray-100">
							{
								(isNull(evaluation.report) && !canGenerateEvaluationReport(evaluation) && !canPublishEvaluationReport(evaluation)) &&
                                <span className="text-center text-base">Rozpocznij badanie, aby wygenerować raport</span>
							}
							{
								isNotNull(evaluation.report) &&
                                <Link
                                    to={ `${ baseUrl }/scope/${ userScope.organization.id }/evaluations/${ evaluation.id }/view-report` }
                                    target="_blank"
                                >
                                    <Button color="primary-outline">
                                        <span>Zobacz raport</span>
                                    </Button>
                                </Link>
							}
							{
								canGenerateEvaluationReport(evaluation) &&
                                <Button
                                    color="primary"
                                    onClick={ onGenerateEvaluationReport }
                                    isProcessing={ isGeneratingReport }
                                >
                                    <span><HiPlus className="mr-2 w-4 h-4"/></span>
                                    <span>Wygeneruj raport</span>
                                </Button>
							}
							{
								canPublishEvaluationReport(evaluation) &&
                                <Button
                                    color="primary"
                                    onClick={ onPublishEvaluationReport }
                                    isProcessing={ isPublishingReport }
                                >
                                    <span><HiCheckCircle className="mr-2 w-4 h-4"/></span>
                                    <span>Opublikuj raport</span>
                                </Button>
							}
						</div>
						{
							isNotNull(evaluation.report) &&
                            <div>
                                <LabelValue
                                    label="Wygenerowano"
                                    value={ DateTime.fromISO(evaluation.report.createdAt).toFormat("dd.MM.yyyy, T") }
                                />
                            </div>
						}
					</Card>
					{
						isNotNull(subjectParticipant) &&
                        <Card>
                            <h3 className="text-[20px] font-semibold">Osoba badana</h3>
                            <div>
                                <div className="grid grid-cols-12 bg-gray-50 p-4">
                                    <span className="text-gray-500 font-semibold text-xs col-span-7">IMIĘ I NAZWISKO</span>
                                    <span className="text-gray-500 font-semibold text-xs text-center col-span-3">STATUS</span>
                                    <span className="text-gray-500 font-semibold text-xs text-center col-span-2">AKCJE</span>
                                </div>
                                <div className="grid grid-cols-12 p-4 border-b border-gray-100">
                                    <div className="col-span-7 flex flex-col gap-0.5">
                                        <div className="flex gap-2">
                                            <span className="font-medium">{ subjectParticipant.name }</span>
											{
												subjectParticipant.surveyLanguage === SurveyLanguage.ENGLISH &&
                                                <div className="bg-gray-100 rounded-lg leading-[1.25] font-medium px-2 text-xs py-1">EN</div>
											}
                                        </div>
                                        <span className="text-sm text-gray-500">
											<a
                                                className="underline hover:no-underline"
                                                target="_blank"
                                                href={ `mailto:${ subjectParticipant.email }` }
                                            >
												{ subjectParticipant.email }
											</a>
										</span>
                                    </div>
                                    <div className="col-span-3 flex items-center justify-center">
                                        <ParticipantSurveyStatus
                                            surveyStatus={ subjectParticipant.participantSurveyStatus }
                                        />
                                    </div>
                                    <div className="col-span-2 flex items-center gap-0.5 justify-center">
										{
											isNotNull(subjectParticipant.survey) &&
                                            <>
                                                <Tooltip content="Otwórz ankiete">
                                                    <Button
                                                        onClick={ () => navigate(`/evaluations/${ evaluation.id }/surveys/${ subjectParticipant.survey?.id }`) }
                                                        color="transparent"
                                                        size="sm"
                                                        className="p-0 [&>span]:p-1"
                                                    >
                                                        <span><RiSurveyFill className="text-primary-700 w-5 h-5"/></span>
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip content="Otwórz publiczną ankiete">
                                                    <Link
                                                        to={ `/answer-survey/${ subjectParticipant.survey?.id }?email=${ subjectParticipant.email }&token=${ subjectParticipant.survey.emailToken }` }
                                                        target="_blank"
                                                    >
                                                        <Button
                                                            color="transparent"
                                                            size="sm"
                                                            className="p-0 [&>span]:p-1"
                                                        >
                                                            <span><BiWorld className="text-primary-700 w-5 h-5"/></span>
                                                        </Button>
                                                    </Link>
                                                </Tooltip>
                                            </>
										}
										{
											canMassRemindEvaluationParticipants(evaluation) &&
                                            <Tooltip content="Wyślij przypomnienie">
                                                <Button
                                                    color="transparent"
                                                    onClick={ () =>
														onSendReminderToSubject({
															id: evaluation.id,
															participantIds: selectedParticipants.map(id => ({ id })),
														})
													}
                                                    size="sm"
                                                    isProcessing={ isSendingReminderToSubject }
                                                    className={
														classNames(
															"p-0 [&>span]:p-1",
															{ "[&>span>span]:mr-0 [&>span>span_svg]:w-5 [&>span>span_svg]:h-5": isSendingReminderToSubject },
														)
													}
                                                >
													<span>
                                                    	<LuRedo2
                                                    	    className={
																classNames(
																	"text-primary-700 h-5 w-5",
																	{ "hidden opacity-0": isSendingReminderToSubject },
																)
															}
                                                    	/>
													</span>
                                                </Button>
                                            </Tooltip>
										}
										{
											canAddOrDeleteEvaluationParticipant(evaluation) &&
                                            <Tooltip content="Edytuj osobę">
                                                <Button
                                                    onClick={ () => toggleUpdateParticipantModal({ isOpen: true, value: subjectParticipant }) }
                                                    color="transparent"
                                                    size="sm"
                                                    className="p-0 [&>span]:p-1"
                                                >
													<span><HiPencil className="text-primary-700 w-5 h-5"/></span>
                                                </Button>
                                            </Tooltip>
										}
                                    </div>
                                </div>
                            </div>
                        </Card>
					}
					<Card>
						<div className="flex justify-between items-center gap-1">
							<h3 className="text-[20px] font-semibold">Lista oceniających</h3>
							<div className="flex gap-2 items-center">
								{
									canAddOrDeleteEvaluationParticipant(evaluation) &&
                                    <Button
                                        size="sm"
                                        color="primary-outline"
                                        onClick={ () => toggleImportParticipantsModal(true) }
                                    >
										<span><FaFileImport className="mr-2 w-4 h-4"/></span>
										<span>Import CSV</span>
                                    </Button>
								}
								{
									canAddOrDeleteEvaluationParticipant(evaluation) &&
                                    <Button
                                        size="sm"
                                        color="primary-outline"
                                        onClick={ () => toggleCreateParticipantModal(true) }
                                    >
										<span><HiPlus className="mr-2 w-4 h-4"/></span>
										<span>Dodaj oceniającego</span>
                                    </Button>
								}
							</div>
						</div>
						{
							evaluation.participants.filter(participant => participant.participantType !== ParticipantType.SUBJECT).length > 0 ?
								<>
									<div>
										<div className="grid grid-cols-12 bg-gray-50 p-4">
											<span className="text-gray-500 font-semibold text-xs col-span-7">IMIĘ I NAZWISKO</span>
											<span className="text-gray-500 font-semibold text-xs text-center col-span-3">STATUS</span>
											<span className="text-gray-500 font-semibold text-xs text-center col-span-2">AKCJE</span>
										</div>
										{
											evaluation.participants
													  .filter(participant => participant.participantType !== ParticipantType.SUBJECT)
													  .map(participant =>
														  <div
															  key={ participant.id }
															  className="grid grid-cols-12 p-4 border-b border-gray-100"
														  >
															  <div className="col-span-7">
																  <div
																	  className={
																		  classNames(
																			  "flex gap-4 w-fit",
																			  { "cursor-pointer": canMassRemindEvaluationParticipants(evaluation) },
																		  )
																	  }
																	  onClick={ () => {
																		  if (!canMassRemindEvaluationParticipants(evaluation)) return;

																		  setSelectedParticipants(prevState => {
																			  if (prevState.includes(participant.id)) {
																				  return prevState.filter(id => id !== participant.id);
																			  } else {
																				  return [ ...prevState, participant.id ];
																			  }
																		  });
																	  } }
																  >
																	  {
																		  canMassRemindEvaluationParticipants(evaluation) &&
                                                                          <div className="flex items-center gap-x-3">
                                                                              <Checkbox
                                                                                  name={ `participant-${ participant.id }` }
                                                                                  checked={ selectedParticipants.includes(participant.id) }
                                                                                  onChange={ () => undefined }
                                                                              />
                                                                          </div>
																	  }
																	  <div className="flex flex-col gap-0.5">
																		  <div className="flex gap-2">
																			  <span className="font-medium">{ participant.name }</span>
																			  {
																				  participant.surveyLanguage === SurveyLanguage.ENGLISH &&
                                                                                  <div className="bg-gray-100 rounded-lg leading-[1.25] font-medium px-2 text-xs py-1">EN</div>
																			  }
																		  </div>
																		  <span className="text-sm text-gray-500">{ participantTypeDictionary[ participant.participantType ] }</span>
																	  </div>
																  </div>
															  </div>
															  <div className="col-span-3 flex justify-center items-center">
																  <ParticipantSurveyStatus
																	  surveyStatus={ participant.participantSurveyStatus }
																  />
															  </div>
															  <div className="col-span-2 flex items-center gap-0.5 justify-center">
																  {
																	  isNotNull(participant.survey) &&
                                                                      <>
                                                                          <Tooltip content="Otwórz ankiete">
                                                                              <Button
                                                                                  onClick={ () => navigate(`/evaluations/${ evaluation.id }/surveys/${ participant.survey?.id }`) }
                                                                                  color="transparent"
                                                                                  size="sm"
                                                                                  className="p-0 [&>span]:p-1"
                                                                              >
																				  <span><RiSurveyFill className="text-primary-700 w-5 h-5"/></span>
                                                                              </Button>
                                                                          </Tooltip>
                                                                          <Tooltip content="Otwórz publiczną ankiete">
                                                                              <Link
                                                                                  to={ `/answer-survey/${ participant.survey?.id }?email=${ participant.email }&token=${ participant.survey.emailToken }` }
                                                                                  target="_blank"
                                                                              >
                                                                                  <Button
                                                                                      color="transparent"
                                                                                      size="sm"
                                                                                      className="p-0 [&>span]:p-1"
                                                                                  >
																					  <span><BiWorld className="text-primary-700 w-5 h-5"/></span>
                                                                                  </Button>
                                                                              </Link>
                                                                          </Tooltip>
                                                                      </>
																  }
																  {
																	  canAddOrDeleteEvaluationParticipant(evaluation) &&
                                                                      <>
                                                                          <Tooltip content="Edytuj osobę">
                                                                              <Button
                                                                                  onClick={ () => toggleUpdateParticipantModal({ isOpen: true, value: participant }) }
                                                                                  color="transparent"
                                                                                  size="sm"
                                                                                  className="p-0 [&>span]:p-1"
                                                                              >
																				  <span><HiPencil className="text-primary-700 w-5 h-5"/></span>
                                                                              </Button>
                                                                          </Tooltip>
                                                                          <Tooltip content="Usuń osobę">
                                                                              <Button
                                                                                  onClick={ () => setDeleteParticipantModal({ isOpen: true, value: participant }) }
                                                                                  color="transparent"
                                                                                  size="sm"
                                                                                  className="p-0 [&>span]:p-1"
                                                                              >
																				  <span><HiOutlineTrash className="text-primary-700 w-5 h-5"/></span>
                                                                              </Button>
                                                                          </Tooltip>
                                                                      </>
																  }
															  </div>
														  </div>,
													  )
										}
									</div>
									{
										(selectedParticipants.length > 0 && canMassRemindEvaluationParticipants(evaluation)) &&
                                        <Alert color="info" className="[&>div]:flex-grow [&>div>div]:flex-grow">
                                            <div className="flex gap-2 items-center justify-between w-full">
                                                <span className="text-gray-900">{ `Co chcesz zrobić z wybranymi (${ selectedParticipants.length }) osobami?` }</span>
                                                <Button
                                                    onClick={ () => {
														onSendReminder({
															id: evaluation.id,
															participantIds: selectedParticipants.map(id => ({ id })),
														});
													} }
                                                    color="transparent"
                                                    size="sm"
                                                    isProcessing={ isSendingReminder }
                                                >
													<span><LuRedo2 className="text-primary-700 w-5 h-5 mr-2"/></span>
													<span>Wyślij przypomnienie</span>
                                                </Button>
                                            </div>
                                        </Alert>
									}
									<div className="flex flex-col gap-2.5">
										{
											Object
												.values(Status)
												.map(status =>
													<div
														key={ status }
														className="flex justify-between items-center gap-1"
													>
														<span className="text-gray-500">{ participantSurveyStatusDictionary[ status ] }</span>
														<span className="font-medium">{ evaluation.participants.filter(participant => participant.participantSurveyStatus === status).length }</span>
													</div>,
												)
										}
										<div className="flex justify-between items-center gap-1">
											<span className="font-bold text-lg">Suma badanych</span>
											<span className="font-bold text-lg">{ evaluation.participants.length }</span>
										</div>
									</div>
								</>
								:
								<div className="text-center text-base mt-2">
									Dodaj pierwszą osobe aby zobaczyć podsumowanie
								</div>
						}
					</Card>
				</div>
			</div>
			<CreateParticipantModal
				isOpen={ isCreateParticipantModalOpen }
				handleClose={ () => toggleCreateParticipantModal(false) }
				evaluationId={ evaluation.id }
				onCreate={ onCreateParticipant }
			/>
			<ImportParticipantsModal
				isOpen={ isImportParticipantsModalOpen }
				handleClose={ () => toggleImportParticipantsModal(false) }
				evaluationId={ evaluation.id }
				onImport={ onImportParticipants }
			/>
			<EditEvaluationModal
				isOpen={ isEditModalOpen }
				handleClose={ () => toggleEditModal(false) }
				evaluation={ evaluation }
				onEdit={ onUpdateEvaluation }
			/>
			<EditEvaluationQuestionsModal
				isOpen={ isEditQuestionsModalOpen }
				handleClose={ () => toggleEditQuestionsModal(false) }
				evaluation={ evaluation }
				onEdit={ onUpdateEvaluationQuestions }
			/>
			{
				isNotNull(updateParticipantModal.value) &&
                <EditParticipantModal
                    isOpen={ updateParticipantModal.isOpen }
                    handleClose={ () => toggleUpdateParticipantModal(prevState => ({ ...prevState, isOpen: false })) }
                    onUpdate={ payload => onUpdateParticipant(payload) }
                    participant={ updateParticipantModal.value }
                    evaluationId={ evaluation.id }
                />
			}
			<ConfirmModal
				title="Udostępnij badanie przedstawicielowi"
				isOpen={ isShareModalOpen }
				warning="Czy jesteś pewień, że chcesz nadać dostęp do badania przedstawicielowi?"
				isLoading={ isSharing }
				handleClose={ () => toggleShareModal(false) }
				confirmText="Nadaj dostęp"
				onConfirm={ () => {
					onShareEvaluationToRepresentative(evaluation.id);
					toggleShareModal(false);
				} }
			/>
			<ConfirmModal
				title="Cofnij dostęp do badania przedstawiciela"
				warning="Czy jesteś pewień? Pamiętaj, że zawsze możesz ponownie nadać dostęp przedstawicielowi"
				isOpen={ isUndoShareModalOpen }
				isLoading={ isUndoSharing }
				handleClose={ () => toggleUndoShareModal(false) }
				confirmText="Cofnij dostęp"
				onConfirm={ () => {
					onUndoShareEvaluationToRepresentative(evaluation.id);
					toggleUndoShareModal(false);
				} }
			/>
			<ConfirmModal
				title={ `Usuń ${ (isNotNull(deleteParticipantModal.value) && !isEmptyString(deleteParticipantModal.value.name)) ? deleteParticipantModal.value.name : "uczestnika" }` }
				warning="Czy jesteś pewień? Ta operacja jest nieodwracalna"
				isOpen={ deleteParticipantModal.isOpen }
				confirmWord="kasuj"
				isLoading={ isDeletingParticipant }
				handleClose={ () => setDeleteParticipantModal({ isOpen: false, value: null }) }
				onConfirm={ _handleConfirmDeleteParticipant }
			/>
		</>
	);
}

const mapStateToProps = (state: RootState, props: ComponentProps) => ({
	isStarting: didLoadingRecordExist(state, { loadableId: props.evaluation.id, loadableType: LoadableType.START_EVALUATION }),
	isFinishing: didLoadingRecordExist(state, { loadableId: props.evaluation.id, loadableType: LoadableType.FINISH_EVALUATION }),
	isGeneratingReport: didLoadingRecordExist(state, { loadableId: props.evaluation.id, loadableType: LoadableType.GENERATE_EVALUATION_REPORT }),
	isPublishingReport: didLoadingRecordExist(state, { loadableId: props.evaluation.id, loadableType: LoadableType.PUBLISH_EVALUATION_REPORT }),
	isSendingReminder: didLoadingRecordExist(state, { loadableId: props.evaluation.id, loadableType: LoadableType.SEND_REMINDER_TO_PARTICIPANTS }),
	isSendingReminderToSubject: didLoadingRecordExist(state, { loadableId: props.evaluation.id, loadableType: LoadableType.SEND_REMINDER_TO_SUBJECT }),
	isSharing: didLoadingRecordExist(state, { loadableId: props.evaluation.id, loadableType: LoadableType.SHARE_EVALUATION_TO_REPRESENTATIVE }),
	isUndoSharing: didLoadingRecordExist(state, { loadableId: props.evaluation.id, loadableType: LoadableType.UNDO_SHARE_EVALUATION_TO_REPRESENTATIVE }),
	isDeleting: (participantId: number) => didLoadingRecordExist(state, { loadableId: participantId, loadableType: LoadableType.DELETE_PARTICIPANT }),
});

export default connect(mapStateToProps)(EvaluationProfileContainer);

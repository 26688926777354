import type { CustomFlowbiteTheme } from "flowbite-react";

const appTheme: CustomFlowbiteTheme = {
	card: {
		root: {
			base: "flex flex-col rounded-lg border border-gray-200 bg-white shadow dark:border-gray-700 dark:bg-gray-800",
		},
	},
	helperText: {
		root: {
			base: "mt-0 text-sm text-red-600 dark:text-red-500",
		},
	},
	button: {
		color: {
			primary: "text-white bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800",
			"primary-outline": "text-primary-800 hover:text-white transition bg-transparent border border-primary-800 hover:bg-primary-800 focus:ring-primary-300",
			light: "text-gray-900 bg-white border border-gray-300 enabled:hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 :bg-gray-600 dark:text-white dark:border-gray-600 dark:enabled:hover:bg-gray-700 dark:enabled:hover:border-gray-700 dark:focus:ring-gray-700",
			transparent: "text-primary-600 dark:text-dark-textGray dark:hover:text-white hover:border-gray-300 dark:hover:border-dark-hoverBg hover:bg-primary-50 dark:hover:bg-dark-hoverBg focus:ring-primary-100 dark:focus:ring-gray-500 transition duration-100 p-0 [&>span]:p-2.5",
		},
		outline: {
			on: "transition-all duration-75 ease-in group-hover:bg-opacity-0 group-hover:text-inherit",
		},
		size: {
			md: "text-sm px-3 py-2",
		},
	},
	checkbox: {
		root: {
			base: "h-4 w-4 rounded border border-gray-300 bg-gray-100 focus:ring-2 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-primary-600 text-primary-600 disabled:cursor-not-allowed disabled:opacity-50",
		},
	},
	dropdown: {
		floating: {
			base: "z-10 w-fit rounded-xl divide-y divide-gray-100 shadow",
			content: "rounded-xl text-sm text-gray-700 dark:text-gray-200",
			target: "w-fit dark:text-white",
		},
		content: "",
	},
	badge: {
		root: {
			base: "flex h-fit items-center gap-1 font-semibold [&>span]:leading-none",
			color: {
				primary: "bg-primary-100 text-primary-800 dark:bg-primary-200 dark:text-primary-800 group-hover:bg-primary-200 dark:group-hover:bg-primary-300",
				info: "bg-cyan-100 text-cyan-800 dark:bg-cyan-200 dark:text-cyan-800 group-hover:bg-cyan-200 dark:group-hover:bg-cyan-300",
				gray: "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300 group-hover:bg-gray-200 dark:group-hover:bg-gray-600",
				failure: "bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900 group-hover:bg-red-200 dark:group-hover:bg-red-300",
				success: "bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900 group-hover:bg-green-200 dark:group-hover:bg-green-300",
				warning: "bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900 group-hover:bg-yellow-200 dark:group-hover:bg-yellow-300",
				indigo: "bg-indigo-100 text-indigo-800 dark:bg-indigo-200 dark:text-indigo-900 group-hover:bg-indigo-200 dark:group-hover:bg-indigo-300",
				purple: "bg-purple-100 text-purple-800 dark:bg-purple-200 dark:text-purple-900 group-hover:bg-purple-200 dark:group-hover:bg-purple-300",
				pink: "bg-pink-100 text-pink-800 dark:bg-pink-200 dark:text-pink-900 group-hover:bg-pink-200 dark:group-hover:bg-pink-300",
				orange: "bg-orange-100 text-orange-800 dark:bg-orange-200 dark:text-orange-900 group-hover:bg-orange-200 dark:group-hover:bg-orange-300",
				blue: "bg-blue-100 text-blue-800 dark:bg-blue-200 dark:text-blue-900 group-hover:bg-blue-200 dark:group-hover:bg-blue-300",
				cyan: "bg-cyan-100 text-cyan-800 dark:bg-cyan-200 dark:text-cyan-900 group-hover:bg-cyan-200 dark:group-hover:bg-cyan-300",
				dark: "bg-gray-600 text-gray-100 dark:bg-gray-900 dark:text-gray-200 group-hover:bg-gray-500 dark:group-hover:bg-gray-700",
				light: "bg-gray-200 text-gray-800 dark:bg-gray-400 dark:text-gray-900 group-hover:bg-gray-300 dark:group-hover:bg-gray-500",
				green: "bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900 group-hover:bg-green-200 dark:group-hover:bg-green-300",
				lime: "bg-lime-100 text-lime-800 dark:bg-lime-200 dark:text-lime-900 group-hover:bg-lime-200 dark:group-hover:bg-lime-300",
				red: "bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900 group-hover:bg-red-200 dark:group-hover:bg-red-300",
				teal: "bg-teal-100 text-teal-800 dark:bg-teal-200 dark:text-teal-900 group-hover:bg-teal-200 dark:group-hover:bg-teal-300",
				yellow: "bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900 group-hover:bg-yellow-200 dark:group-hover:bg-yellow-300",
			},
			href: "group",
			size: {
				xs: "p-1.5 text-xs",
				sm: "p-1.5 text-sm",
				"pill-xs": "rounded-full p-1.5 text-xs",
				"pill-sm": "rounded-full p-1.5 text-sm",
			},
		},
		icon: {
			off: "rounded px-2 py-0.5",
			on: "rounded-full p-1.5",
			size: {
				xs: "w-3 h-3",
				sm: "w-3.5 h-3.5",
			},
		},
	},
	modal: {
		root: {
			base: "fixed top-0 right-0 left-0 z-50 h-full overflow-y-auto overflow-x-hidden md:inset-0 md:h-full z-[70]",
		},
		content: {
			inner: "relative rounded-lg bg-white shadow dark:bg-gray-800 overflow-auto h-full",
		},
		header: {
			base: "flex items-start justify-between rounded-t px-5 pt-5",
		},
	},
	navbar: {
		root: {
			base: "fixed z-30 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700",
		},
	},
	sidebar: {
		root: {
			base: "flex fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16 h-full duration-75 border-r border-gray-200 lg:flex transition-width dark:border-gray-700",
			inner: "h-full overflow-y-auto overflow-x-hidden rounded bg-white py-4 px-3 dark:bg-gray-800",
		},
	},
	textInput: {
		field: {
			input: {
				base: "block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500",
				colors: {
					gray: "", // For clearing whole input classNames
				},
			},
		},
	},
	textarea: {
		base: "block w-full text-sm p-4 rounded-lg border disabled:cursor-not-allowed disabled:opacity-50",
	},
	toggleSwitch: {
		toggle: {
			checked: {
				off: "!border-gray-200 !bg-gray-200 dark:!border-gray-600 dark:!bg-gray-700",
			},
		},
	},
	avatar: {
		root: {
			base: "flex justify-center items-center space-x-4 rounded",
			initials: {
				text: "font-medium text-gray-600 dark:text-gray-300",
				base: "inline-flex overflow-hidden relative justify-center items-center bg-gray-100 dark:bg-gray-600 rounded leading-none",
			},
			img: {
				base: "rounded bg-gray-100 dark:bg-gray-600",
				off: "relative overflow-hidden bg-gray-100 dark:bg-gray-600",
				on: "",
				placeholder: "absolute w-auto h-auto text-gray-400 -bottom-1",
			},
		},
	},
	tab: {
		tablist: {
			tabitem: {
				base: "flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500",
			},
		},
	},
};

export default appTheme;

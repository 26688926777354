import { Button } from "flowbite-react";
import React from "react";

type Props = {
	onClick: () => void
	children: any
}

function EvaluationButton(props: Props) {

	const {
		onClick,
		children,
	} = props;

	return (
		<Button
			className="w-fit flex"
			color="gray"
			size="sm"
			onClick={ onClick }
		>
			<div className="flex items-center">
				{ children }
			</div>
		</Button>
	);

}

export default EvaluationButton;
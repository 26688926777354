/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createNetworkAction } from "src/app/utils/redux";
import { FailurePayload, FailurePayloadWithId, SuccessPayload, SuccessPayloadWithId } from "src/app/types/api/api.types";
import { CreateEvaluationPayload, CreateMassReportPayload, DetailedEvaluation, Evaluation, ExternalEvaluation, FetchExternalEvaluationRequest, UpdateEvaluationPayload } from "src/app/types/api/evaluation.types";

export const fetchEvaluationsAsync = createNetworkAction<undefined, SuccessPayload<Evaluation[]>>("EVALUATION__FETCH_EVALUATIONS");
export const fetchEvaluationByIdAsync = createNetworkAction<number, SuccessPayloadWithId<DetailedEvaluation>, FailurePayloadWithId>("EVALUATION__FETCH_EVALUATION_BY_ID");
export const fetchExternalEvaluationByIdAsync = createNetworkAction<FetchExternalEvaluationRequest, SuccessPayloadWithId<ExternalEvaluation>, FailurePayloadWithId>("EVALUATION__FETCH_EXTERNAL_EVALUATION_BY_ID");

export const createEvaluationAsync = createNetworkAction<CreateEvaluationPayload, SuccessPayload<DetailedEvaluation>>("EVALUATION__CREATE_EVALUATION");
export const updateEvaluationAsync = createNetworkAction<UpdateEvaluationPayload, SuccessPayload<DetailedEvaluation>>("EVALUATION__UPDATE_EVALUATION");
export const archiveEvaluationAsync = createNetworkAction<number, SuccessPayload<DetailedEvaluation>>("EVALUATION__ARCHIVE_EVALUATION");
export const shareEvaluationToRepresentativeAsync = createNetworkAction<number, SuccessPayload<DetailedEvaluation>>("EVALUATION__SHARE_EVALUATION_TO_REPRESENTATIVE");
export const undoShareEvaluationToRepresentativeAsync = createNetworkAction<number, SuccessPayload<DetailedEvaluation>>("EVALUATION__UNDO_SHARE_EVALUATION_TO_REPRESENTATIVE");

export const startEvaluationAsync = createNetworkAction<number, SuccessPayload<DetailedEvaluation>>("EVALUATION__START_EVALUATION");
export const finishEvaluationAsync = createNetworkAction<number, SuccessPayload<DetailedEvaluation>>("EVALUATION__FINISH_EVALUATION");
export const generateEvaluationReportAsync = createNetworkAction<number, SuccessPayload<DetailedEvaluation>>("EVALUATION__GENERATE_EVALUATION_REPORT");
export const publishEvaluationReportAsync = createNetworkAction<number, SuccessPayload<DetailedEvaluation>>("EVALUATION__PUBLISH_EVALUATION_REPORT");

export const deleteEvaluationByIdAsync = createNetworkAction<number, SuccessPayload<{ id: number }>>("EVALUATION__DELETE_EVALUATION");

export const createMassReportAsync = createNetworkAction<CreateMassReportPayload, SuccessPayload<{ url: string }>, FailurePayload>("EVALUATION__CREATE_MASS_REPORT");
export type LoadingRecord = {
	loadableId?: string | number,
	loadableType: LoadableType
}

export enum LoadableType {
	LOGIN = "LOGIN",
	AUTH_ME = "AUTH_ME",
	FORGOT_PASSWORD = "FORGOT_PASSWORD",
	RESET_PASSWORD = "RESET_PASSWORD",

	FETCH_USER_SCOPES = "FETCH_USER_SCOPES",
	APP_DASHBOARD_LOADING = "APP_DASHBOARD_LOADING",

	CREATE_USER = "CREATE_USER",
	UPDATE_USER = "UPDATE_USER",
	UPDATE_USER_STATUS = "UPDATE_USER_STATUS",
	UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD",
	UPDATE_USER_PERMISSIONS = "UPDATE_USER_PERMISSIONS",
	DELETE_USER = "DELETE_USER",

	DISPLAY_USER_TWO_FA_MODAL = "DISPLAY_USER_TWO_FA_MODAL",
	CONFIRM_USER_TWO_FA = "CONFIRM_USER_TWO_FA",
	DISABLE_USER_TWO_FA = "DISABLE_USER_TWO_FA",

	/* Companies */
	CREATE_COMPANY = "CREATE_COMPANY",
	UPDATE_COMPANY = "UPDATE_COMPANY",
	DELETE_COMPANY = "DELETE_COMPANY",

	/* Organizations */
	CREATE_ORGANIZATION = "CREATE_ORGANIZATION",
	UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION",
	DELETE_ORGANIZATION = "DELETE_ORGANIZATION",

	/* Evaluations */
	CREATE_EVALUATION = "CREATE_EVALUATION",
	UPDATE_EVALUATION = "UPDATE_EVALUATION",
	UPDATE_EVALUATION_QUESTIONS = "UPDATE_EVALUATION_QUESTIONS",
	START_EVALUATION = "START_EVALUATION",
	FINISH_EVALUATION = "FINISH_EVALUATION",
	GENERATE_EVALUATION_REPORT = "GENERATE_EVALUATION_REPORT",
	SEND_REMINDER_TO_SUBJECT = "SEND_REMINDER_TO_SUBJECT",
	SEND_REMINDER_TO_PARTICIPANTS = "SEND_REMINDER_TO_PARTICIPANTS",
	PUBLISH_EVALUATION_REPORT = "PUBLISH_EVALUATION_REPORT",
	DELETE_EVALUATION = "DELETE_EVALUATION",
	CHANGE_EVALUATION_COORDINATOR_USER = "CHANGE_EVALUATION_COORDINATOR_USER",
	ARCHIVE_EVALUATION = "ARCHIVE_EVALUATION",
	SHARE_EVALUATION_TO_REPRESENTATIVE = "SHARE_EVALUATION_TO_REPRESENTATIVE",
	UNDO_SHARE_EVALUATION_TO_REPRESENTATIVE = "UNDO_SHARE_EVALUATION_TO_REPRESENTATIVE",

	/* Mass Reports */
	CREATE_MASS_REPORT = "CREATE_MASS_REPORT",

	/* Participants */
	CREATE_PARTICIPANT = "CREATE_PARTICIPANT",
	UPDATE_PARTICIPANT = "UPDATE_PARTICIPANT",
	DELETE_PARTICIPANT = "DELETE_PARTICIPANT",
	IMPORT_PARTICIPANTS = "IMPORT_PARTICIPANTS",

	/* Survey */
	SAVE_SURVEY = "SAVE_SURVEY",
	SUBMIT_SURVEY = "SUBMIT_SURVEY"
	// Loadable types - don't remove
}
